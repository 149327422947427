<template>
    <div>
      <BannerHome />

      <MinhaColecao />

      <Indique />

      <Beneficios />

       <VemPorAi />

      <FiqueDeOlho />

      <TheFooter />

    </div>
</template>

<script>
import FiqueDeOlho from "@/components/FiqueDeOlho.vue";
import MinhaColecao from "@/components/MinhaColecao.vue";
import VemPorAi from "@/components/VemPorAi.vue";
import BannerHome from "@/components/BannerHome.vue";
import Indique from "@/components/Indique.vue";
import Beneficios from "@/views/Beneficios.vue";
import TheFooter from "@/components/Base/TheFooter.vue";
export default {
 name: "Home",
 components: {
  Beneficios,
    BannerHome,
    TheFooter,
    MinhaColecao,
    FiqueDeOlho,
    VemPorAi,
    Indique
  },

};
</script>
