<template>
    <div>

        <div class="box-beneficios">

            <router-link to="/cadastro" style="text-decoration: none; color: inherit" data-anima="bottom">
                <img
                    src="@/assets/img/beneficios-start.png"
                    alt="Imagem de benefícios"
                    class="bg-beneficios desk"
                />
            </router-link>

            <router-link to="/cadastro" style="text-decoration: none; color: inherit" data-anima="bottom">
                <img
                        src="@/assets/img/beneficiosmobstart.png"
                        alt="Imagem de benefícios"
                        class="bg-beneficios mob"
                    />
            </router-link>

        <!--<section>
            <div class="d-flex container conteudo-beneficios">
                <h2>BenefÍcios</h2>
                <img src="@/assets/img/logo_legionarios_branca.svg" alt="Logo Stanley Legionários" class="logo-titulo" />
            </div>
        </section>
        <section class="beneficios">
            <div class="passos container d-flex">
                <div class="conteudo-passo">
                    <img src="@/assets/img/img-beneficio.png" alt="Logo Stanley Legionários" class="img-beneficio"/>
                    <div class="d-flex txtpasso">
                        <img  src="@/assets/img/stanley.svg" alt="Logo Stanley Legionários" class="logo-topo" />
                        <p>1</p>
                        <h3>Cadastre seus produtos Stanley</h3>
                    </div>
                    <p class="infopasso">Caso não tenha comprado em nossa loja oficial, cadastre seus produtos manualmente.</p>
                </div>
                <div class="div-seta">
                    <img src="@/assets/img/SETA.svg" alt="Logo Stanley Legionários" class="img-seta" />
                </div>
                <div  class="conteudo-passo">
                    <img src="@/assets/img/img-beneficio.png" alt="Logo Stanley Legionários" class="img-beneficio"/>
                    <div class="d-flex txtpasso">
                        <img  src="@/assets/img/stanley.svg" alt="Logo Stanley Legionários" class="logo-topo" />
                        <p>2</p>
                        <h3>Acumule<br> Pontos</h3>
                    </div>
                    <p class="infopasso">Produtos cadastrados, indicações e engajamento valem pontos.</p>
                </div>
                <div class="div-seta">
                    <img src="@/assets/img/SETA.svg" alt="Logo Stanley Legionários" class="img-seta" />
                </div>
                <div  class="conteudo-passo">
                    <img src="@/assets/img/img-beneficio.png" alt="Logo Stanley Legionários" class="img-beneficio"/>
                    <div class="d-flex txtpasso">
                        <img  src="@/assets/img/stanley.svg" alt="Logo Stanley Legionários" class="logo-topo" />
                        <p>3</p>
                        <h3>Troque</h3>
                    </div>
                    <p class="infopasso">Além de benefícios, acessórios e produtos exclusivos para os legionários, você pode trocar seus pontos por cupons de desconto!</p>
                </div>
            </div>
        </section>-->
        </div>
   
    </div>
</template>

<script>
export default {
  name: "Beneficios",
};
</script>

<style scoped>
.box-beneficios {
    background-color: #708573;
}

.box-brindes {
    background-color: #000;
}

.box-brindes img {
    position: relative;
}

.conteudo-passo h3 {
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 20px;
    width: 180px;
}

.conteudo-passo p {
    font-size: 67px;
    font-family: "Knockout";
    color: #FFFFFF;
}

.conteudo-beneficios h2 {
    color: #FF681E;
    font-size: 76px;
    font-family: "klavika medium";
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-top: 25px;
}

.logo-titulo {
    width: 500px;
    margin-left: 20px;
}

.conteudo-beneficios {
    padding-top: 20px;
}

.img-beneficio {
    width: 348px;
}

.passos {
    gap: 20px;
    align-items: center;
    padding-top: 20px;
    justify-content: space-between;
    padding-bottom: 70px;
}

.img-seta {
    width: 40px;
    transform: rotate(-180deg);
}

.txtpasso {
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.infopasso {
    color: white;
    font-size: 17px !important;
    font-family: "klavika light"!important;
    width: 340px;
    margin-top: -20px;
    line-height: 20px;
}

@media (max-width: 990px) {

    .conteudo-beneficios h2 {
        font-size: 50px;
        letter-spacing: 0px;
        margin-top: 0px !important;
        padding-top: 20px;
    }

    .infopasso {
        margin-bottom: 40px;
    }
    
    .logo-titulo {
        margin-top: -10px;
        width: 90%;
        margin-left: 0px;
    }

    .div-seta {
        display: none;
    }

    .conteudo-beneficios {
        display: block !important;
    }

    .passos {
        padding-bottom: 30px;
    }

    .beneficios {
        overflow: auto;
    }

    .img-beneficio {
        width: 300px;
    }

    .desk {
        display: none;
    }

    .box-brindes .bg-beneficios {
        margin-top: -45px;
    }

     .box-beneficios .bg-beneficios {
        margin-top: -45px;
        position: relative;
        z-index: 99999999;
    }

}

@media (min-width: 991px) {
    .mob {
        display: none;
    }

    .bg-beneficios {
         margin-top: -70px;
    }
}

@media (min-width: 991px) and (max-width: 1399px) {
    .img-beneficio, .conteudo-passo {
        width: 300px !important;
    }

    .infopasso {
        width: 300px;
    }
}

</style>