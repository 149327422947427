<template>

    <section class="login" data-anima="bottom">
        <div class="loading_global" v-if="loading">
            <span class="loader"></span>
        </div>
        <div v-if="!loading">
            <router-link to="/"><img
                src="@/assets/img/logo_legionarios_preto.svg"
                alt="Logo Stanley Legionários"
                class="logo"
            /></router-link>
            <h1><strong class="strong">BOM TE VER</strong> POR AQUI!</h1>
            
            <div class="form-login">
                <Notificacao
                  :infos="notificacao_infos"
                  v-if="notificacao_infos.mensagem"
                />
                <form @submit.prevent="SubmitLogin">
                    <label for="email">Endereço de e-mail</label>

                    <input type="email" id="email" name="email" v-model="login.email" required ><br><br>
                    <label for="senha">Senha</label>
                    <div class="campo-senha">
                        <input :type="mostrarSenha ? 'text' : 'password'" id="senha" name="senha"  v-model="login.senha" ref="senhaInput" required><br><br>
                        <div @click.prevent="toggleMostrarSenha" class="btn-view">
                            <img v-if="mostrarSenha"
                                src="@/assets/img/noview.png"
                                alt="Icone de visualização"
                                class="view"
                            />
                            <img v-else
                                src="@/assets/img/view.png"
                                alt="Icone de visualização"
                                class="view"
                            />
                        </div>
                    </div>
                    <input type="submit" value="ENTRAR" class="btn-entrar">
                </form>

                <div class="link">
                    <router-link to="/esqueceu-a-senha" >
                        Esqueci a senha
                    </router-link>
                </div>

                <!--<div class="box-linhas">
                    <div class="linha"></div>
                    <div><p class="txt">ou</p></div>
                    <div class="linha"></div>
                </div>

                <BotaoLogin />-->

                <p class="txtcadastro">Ainda não tem conta? 
                    <strong>
                        <router-link to="/cadastro">Cadastre-se já</router-link>
                    </strong>
                </p>

                
                
            </div>
             <TheFooter />
        </div>
        
    </section>
   
</template>

<script>

import TheFooter from "@/components/Base/TheFooter.vue";

export default {
  name: "Login",
  components: {
    TheFooter
  },
  data() {
    return {
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
      mostrarSenha: false,
      loading: false,
      login: {
        email: '',
        senha: ''
      },
      emailURL: "",
      decodedEmail: ""
    };
  },
  methods: {
    toggleMostrarSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },

    SubmitLogin() {
      this.loading = true;
      this.$store
          .dispatch("logarUsuario", this.login)
          .then(() => {
            setTimeout(() => {
                this.$router.push({
                  path: "/home",
                  query: this.$route.query,
                });
            }, 200);
          })
          .catch((error) => {
            console.log(error);
            this.notificacao_infos = {
              variant: "error",
              mensagem: error.response
                ? error.response.data.errors[0]?.message
                : "Algo deu errado na requisição",
              count_timer: 10,
            };
            this.loading = false;
            
        });
    },
    deslogarUsuario() {
      this.$store.dispatch('deslogarUsuario');
    }
  },
  mounted() {
    this.emailURL = this.$route.query.e;
    if(this.emailURL){
      this.login.email = atob(this.emailURL);
      this.$refs.senhaInput.focus();
    }

    if (window.localStorage.token) {
      this.$router.push({ path: "/home"});
    } 
  },

};
</script>

<style scoped>

.view{
  margin-top: 15px;
}
.txtcadastro {
    color: #52575A;
    font-size: 16px;
    text-align: center;
    margin: 40px 0px;
}

.btn-entrar {
    margin-top: 20px;
}
@media (max-width: 990px) {
  .login .logo {
      padding-top: 40px;
      padding-bottom: 20px;
  }

  .login h1 {
      margin: 20px 0px;
  }
  
  .txtcadastro {
      margin: 30px 0px;
  }

  .copyright {
      margin-bottom: 30px;
  }
}
</style>