<template class="bg-pesquisa">
  <div class="background bg-pesquisa">
    <section>
        <div class="loading_global" v-if="loading">
          <span class="loader"></span>
        </div>

        <div v-else>

          <div class="container">
            <MenuMobile />
          </div>

          <div class="container box-voltar">
            <div class="voltar">
                  <button type="button" class="anterior" @click="voltar">
                      <img
                          src="@/assets/img/left.png"
                          alt="icone seta para esquerda"
                          class="seta"
                      />
                  </button>
                  <p><span>Personalizar Portal</span> <br />Home</p>
            </div>
          </div>

          <div class="form-produto" data-anima="top">
            <form v-if="!finalizada"> 
              <div>
                  <div>
                      <p class="pergunta" v-if="pesquisa.pergunta">{{ pesquisa.pergunta.descricao }}</p>
                    <!-- <p class="pular" v-if="etapa < totalEtapas" v-on:click="proximaEtapa" >Pular</p>-->
                  </div>

                  <div class="pesquisa">
                    <div class="box-resposta" v-for="(resposta, index) in respostasPergunta" :key="index">
                      <div class="d-flex opcao-resposta" v-on:click="responderPergunta(resposta)">

                        <p class="index">{{ index + 1 }}</p>
                        <!-- <img :src="`https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/respostas/icone-resposta${resposta.imagem}.svg`" alt="ícone de resposta" />-->
                          <p>{{ resposta.descricao }}</p>
                      </div>
                    </div>
                  </div>
              </div>
            </form>
            <div v-else class="container">
              <p class="txtcadastrado">Obrigado! Pesquisa encerrada.</p>
                <router-link to="/home" class="btn-personalizar" style="text-decoration: none;">
                    IR PARA HOME
                </router-link>
            </div>
            
          </div>
          <TheFooter />
        </div>
  </section>
 
  </div>
</template>

<script>
import { api } from "@/services.js";
import MenuMobile from "@/components/Base/MenuMobile.vue";
import TheFooter from "@/components/Base/TheFooter.vue";
export default {
  name: "PersonalizarPortal",
  components: {
    MenuMobile,
    TheFooter
  },

  data() {
    return {
      loading: false,
      currentRoute: '',
      pesquisa: [],
      respostasPergunta: [],
      finalizada: false
    }
 },
 created() {
    this.currentRoute = this.$route.path; 
  },
   methods: {
      voltar() {
        this.$router.push("/home")
      },
      perguntas() {
        this.loading = true;
        api
          .get('/pesquisa')
          .then((resp) => {
            this.pesquisa = resp.data;
            this.respostasPergunta = resp.data.pergunta.respostas;
          })
          .catch((error) => {
            console.log(error);
            this.finalizada = true;
          })
          .finally(() => {
            this.loading = false;
          });
      },   
      responderPergunta(idResposta) {
        this.loading = true;
        this.idPesquisa = this.pesquisa.idPesquisa;
        this.idResposta = idResposta;
        this.idPesquisaPergunta = this.pesquisa.pergunta.idPesquisaPergunta;
        this.idResposta = idResposta.idPesquisaPerguntaResposta;

        const data = {
              idPesquisa: this.idPesquisa,
              idPesquisaPergunta: this.idPesquisaPergunta,
              idRespostas: [this.idResposta]
        };


        api
                .post("/pesquisa", data)
                    .then((response) => {
                    console.log(response.data);                 
                      this.pesquisa = response.data;   
                      if (response.data.pergunta && response.data.pergunta.respostas) {
                          this.loading = true;
                          this.respostasPergunta = response.data.pergunta.respostas;
                      } else {
                          this.finalizada = true;
                      }

                })
                .catch((error) => {
                    console.error(error);

                })
                 .finally(() => {
                    this.loading = false;
                });

      },
    
  },
  mounted() {
    this.perguntas();
  },
};
</script>

<style scoped>

    .txtcadastrado {
      margin-bottom: 0px;
      font-family: "klavika-web", sans-serif;
      font-weight: 900;
      font-size: 28px;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      margin-top: 100px;
    }

    .box-resposta {
        background-color: white;
        margin-bottom: 15px;
        width: 350px;
        border: 2px solid #232527;
        cursor: pointer;
    }

    .opcao-resposta img {
        width: 80px;
    }

    .opcao-resposta {
        background-color: white;
        height: 100px;
        margin: 5px;
        width: 97%;
        align-items: center;
        justify-content: start;
        padding: 20px 28px;
        border: 1px solid #FF681E;
        transition: background-color 0.2s ease;
    }

    .opcao-resposta:hover {
      background-color: #232527;
      border: 1px solid #232527;
    }

    .opcao-resposta:hover p {
      color: white !important;
    }

    .opcao-resposta:hover img {
      filter: invert(100%);
    }

    .opcao-resposta p {
        line-height: 23px;
        margin-bottom: 0px;
        font-family: "klavika medium";
        text-align: left;
        color: #52575A;
        font-size: 19px;
        font-weight: 600;
    }

    .img-icone {
        margin-top: 23px;
        width: 33px !important;
    }

    .pular {
        cursor: pointer;
        font-size: 14px;
    }

    .btn-personalizar {
        margin-top: 30px !important;
        margin-bottom: 100px !important;
        transition: background-color 0.3s ease;
        background-color: #00abc8;
        color: white;
        font-size: 20px;
        box-shadow: 2px 3px 9px #00000033;
        cursor: pointer;
        padding: 10px 50px;
        letter-spacing: 0.36px;
        font-family: "klavika-web", sans-serif;
        margin: 0 auto;
        display: table;
    }

    .btn-personalizar:hover {
      background-color: #00b6d5;
    }

    .pergunta {
        color: #232527;
        font-size: 28px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 50px;
    }

    .form-produto {
      width: 800px;
      margin: 0 auto;
    }

    .anterior {
        background-color: transparent;
        border: none;
    }

    .seta {
        width: 14px;
        margin-right: 15px;
        cursor: pointer;
    }

    .voltar {
        display: flex;
        margin: 15px 0px 30px 0px;
    }

    .voltar span {
        color: #52575a;
        font-weight: 200;
    }

    .voltar p {
        margin-bottom: 0px;
        line-height: 17px;
        color: #232527;
        font-size: 14px;
        font-family: "klavika-web", sans-serif;
        font-weight: 700;
    }

    .logo-rodape {
        margin: 0 auto;
        padding-top: 80px;
    }

    .mob {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    .copyright {
        margin-top: 20px;
        margin-bottom: 0px !important;
    }

    .index {
      font-family: "KNOCKOUT-HTF70" !important;
      color: #FF681E !important;
      font-size: 78px !important;
      margin-right: 10px !important;
    }

  .btn-avancar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 300px;
    height: 48px;
    margin-bottom: 30px;
  }

  .btn-avancar:hover {
    background-color: #00b6d5;
  }

  input {
    padding: 5px;
  }
  
  .linha-cinza {
    height: 1px;
    width: 300px;
    background-color: #a2a2a2;
  }

  .linha-etapa {
    height: 1px;
  }

  .etapa-um {
    width: 100px;
    background-color: #00abc8;
  }

  .etapa-dois {
    width: 200px;
    background-color: #00abc8;
  }

  .etapa-tres {
    width: 300px;
    background-color: #00abc8;
  }

  form {
    margin-top: 50px;
  }

  .pesquisa {
    margin-bottom: 50px !important;
    margin: 0 auto;
    display: table;
  }

  @media (min-width: 1500px) {
    .btn-personalizar {
      margin-bottom: 250px !important;
    }
  }


@media (max-width: 990px) {

  .opcao-resposta {
    padding: 20px 30px;
  }

  .logo-mobile {
    width: 150px;
  }
  
  .copyright {
    padding-bottom: 20px;
  }

  .btn-avancar, .form-produto {
    width: 100%;
  }

  .logo-rodape {
    margin: 0 auto;
    padding-top: 80px;
  }

  .linha-cinza {
    width: 100%;
  }

  .etapa-um {
    width: 33%;
  }

  .etapa-dois {
    width: 66%;
  }

  .etapa-tres {
    width: 100%;
  }

  .index {
      width: 40px !important;
  }
   
}

@media (min-width: 991px) {
  .bg-pesquisa {
    background-image: url('@/assets/img/bg-pesquisa.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  }
}

@media (min-width: 1500px) {
  .bg-pesquisa {
    height: 100vh;
  }
}
</style>