<template>
  <div>
    <section v-if="!sucesso" class="container">

        <div class="loading_global" v-if="loading">
          <span class="loader"></span>
        </div>

        <div class="formulario">
                <img
                    src="@/assets/img/logo_legionarios_preto.svg"
                    alt="Logo Stanley Legionários"
                    class="logo"
                />
            <div class="titulo-perfil">
                <h2>COMPLETE <strong>SEU CADASTRO</strong></h2>
            </div>

            <form @submit.prevent="editarPerfil" v-if="!loading">
                  
                   <label for="cpf">CPF</label>
                      <the-mask
                          type="text"
                          id="cpf"
                          name="cpf"
                          v-model="usuarioLogado.cpf"
                          :mask="'###.###.###-##'"
                          :masked="false"
                          inputmode="numeric"
                          required
                        />

                     <label for="celular">Celular</label>
                       <the-mask
                          type="text"
                          id="celular"
                          name="celular"
                          v-model="usuarioLogado.celular"
                          :mask="'(##) #####-####'"
                          inputmode="numeric"
                          required
                          :masked="false"
                        /> 

                        <div class="checkbox-form">
                        <input
                          type="checkbox"
                          id="termo"
                          name="termo"
                          value="termos"
                          required
                        />
                        <label for="termo"
                          >Eu concordo com o Termos e Condições de Uso do Portal Legionários</label ><br />
                      </div>
                 
                  <div class="box-atualizar">
                    <button type="submit" class="btn-avancar margin-btn">FINALIZAR</button>
                  </div>
            </form>  
           
        </div>

  </section>

  <section class="sucesso" v-else>
         <img
                src="@/assets/img/logo_legionarios_branca.svg"
                alt="Logo Stanley Legionários"
                class="logo"
        />

        <div class="conteudo-sucesso" data-anima="bottom">
          <h1>PERFIL ATUALIZADO COM <strong>SUCESSO!</strong></h1>

          <router-link to="/home" style="text-decoration: none;" >
            <button type="button" class="btn-login">IR PARA A HOME</button>
          </router-link>
        </div>
    </section>
<TheFooter />
  </div>
</template>
<script>
import { api } from "@/services.js";
import TheFooter from "@/components/Base/TheFooter.vue";
export default {
  name: "CadastroRedeSocial",
  components: {
    TheFooter
  },
  data() {
    return {
      loading: false,
      sucesso: false,
      usuarioLogado: {
        cpf: "",
        celular: ""
      }
    }
 },
  methods: {

    buscarUsuarios() {
        this.loading = true;
        api
          .get('/usuario/logged')
          .then((resp) => {
            this.usuarioLogado = resp.data;

            if (this.usuarioLogado.cpf && this.usuarioLogado.celular) {
              this.$router.push('/home');
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

       editarPerfil() {
        this.loading = true;
        
        const data = {
            ...this.usuarioLogado,
        };

        api
                .put("/usuario", data)
                    .then((response) => {
                    console.log(response.data);
                    this.sucesso = true;
                })
                .catch((error) => {
                    console.error(error);

                })
                 .finally(() => {
                    this.loading = false;
                });
        
      }
  },
  mounted() {
    this.buscarUsuarios();
  }

};
</script>

<style scoped>

  .logo-rodape {
        margin: 0 auto;
        padding-top: 80px;
  }

  .btn-personalizar {
      margin-top: 50px !important;
      transition: background-color 0.3s ease;
      background-color: #00abc8;
      color: white;
      font-size: 20px;
      box-shadow: 2px 3px 9px #00000033;
      cursor: pointer;
      padding: 10px 50px;
      letter-spacing: 0.36px;
      font-family: "klavika-web", sans-serif;
      margin: 0 auto;
      display: table;
  }

  .btn-personalizar:hover {
      background-color: #00b6d5;
  }

  .box-atualizar {
      margin: 0 auto;
      display: table;
      padding-top: 20px;
      padding-bottom: 70px;
  }

  label {
    font-family: "klavika bold";
    color: #232527;
    font-size: 14px;
    margin-top: 20px;
  }

  form {
    width: 300px;
    margin: 0 auto;
  }

  .btn-mobile {
    padding: 5px !important;

    right: 15px;
    border: none;
    background-color: transparent;
  }

  .btn-mobile:hover,  
  .btn-mobile:focus,  
  .btn-mobile:active, .side-mob .btn:active {
    border: none !important;
    background-color: transparent !important;
  }

  .logo-mobile {
    width: 200px;
    top: 35px;
    left: 20px;
  }

  .espaco {
    margin-top: 20px;
  }

  .checkbox-form {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }

  #termo {
    position: relative;
    top: 3px;
  }

  .titulo-perfil {
        display: flex;
        justify-content: space-between;
        width: 300px;
        margin: 0 auto;
        margin-top: 50px;
    }

    .titulo-perfil h2 {
        color: #232527;
        font-size: 25px;
        text-align: center !important;
    }

  .mob {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
  }

  .copyright {
    margin-top: 20px;
  }

  .btn-avancar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 300px;
    height: 48px;
  }

  .btn-avancar:hover {
    background-color: #00b6d5;
  }

  input {
    padding: 5px;
  }

  .data-nasc input,
  .data-nasc select {
    color: #52575a;
    font-size: 16px;
    font-family: "klavika-web-display", sans-serif;
    font-weight: 200;
  }

  #dataNascimento {
    width: 100% !important;
    font-weight: 600 !important;
    color: black !important;
    font-family: "klavika-web", sans-serif !important;
  }

  .data-nasc {
      width: 100%;
      margin-bottom: 20px;
  }

.sucesso {
    height: 100vh;
    background-color: #D4D1C4;
}

.sucesso h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
  line-height: 35px;
  margin-bottom: 20px;
}

.sucesso strong {
  color: #FFFFFF;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.sucesso p {
  color: #FFFFFF;
  font-size: 16px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.conteudo-sucesso {
    width: 350px;
    margin: 0 auto;
    padding-top: 120px;
}

.btn-login {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 350px;
  height: 48px;
  margin: 30px auto;
  display: block;
}

.btn-login:hover {
  background-color: #00b6d5;
}

.sucesso {
    background-image: url('@/assets/img/cadastro_prod_desk.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 990px) {
   .conteudo-sucesso {
      padding: 0px 10px;
      width: 100%;
      position: absolute;
      bottom: 0px;
  }

  .sucesso {
    background-image: url('@/assets/img/cadastro_prod_mob.jpg');

  }

  .titulo-perfil {
    align-items: center;
    width: 100%;
  }

  .titulo-perfil h2 {
      font-size: 25px;
  }

  .logo-mobile {
    width: 150px;
  }

  .copyright {
    margin-bottom: 30px;
  }

  form {
    width: 100% !important;
    padding-top: 0px;
  }

  .box-atualizar {
    display: block;
  }

  .btn-avancar {
    width: 100%;
  }

  .logo-rodape {
    padding-top: 40px;
  }
}
</style>