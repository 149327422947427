<template>
  <div>
       <section class="container" v-if="!sucesso">  
         <div class="loading_global" v-if="loading">
            <span class="loader"></span>
        </div> 
          <MenuMobile />
          <div class="container voltar">
                  <button type="button" class="anterior" @click="voltar">
                      <img
                          src="@/assets/img/left.png"
                          alt="icone seta para esquerda"
                          class="seta"
                      />
                  </button>
                  <p><span>Meu Perfil </span> <br />Editar senha</p>
              </div>  
            <div class="form-login">

              <Notificacao
                :infos="notificacao_infos"
                v-if="notificacao_infos.mensagem"
              />
                <form>        
                  <label for="senha">Senha atual</label>
                    <div class="campo-senha">
                        <input :type="mostrarSenhaAtual ? 'text' : 'password'" id="senha" v-model="senhaAtual" name="senha" required><br><br>  
                        <div @click.prevent="toggleMostrarSenhaAtual" class="btn-view">
                                <img v-if="mostrarSenhaAtual"
                                    src="@/assets/img/noview.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                                <img v-else
                                    src="@/assets/img/view.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                            </div>                    
                    </div>
                    <label for="confirmarsenha" class="labelsenha">Nova senha</label>
                    <div class="campo-senha">
                        <input :type="mostrarSenha ? 'text' : 'password'" id="confirmarsenha" name="confirmarsenha" v-model="senha" required onpaste="return false" ondrop="return false"><br><br>
                        <div @click.prevent="toggleMostrarSenha" class="btn-view">
                                <img v-if="mostrarSenha"
                                    src="@/assets/img/noview.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                                <img v-else
                                    src="@/assets/img/view.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                            </div>
                    </div>

                    <label for="confirmar-senha" class="confirmar-senha">Confirmar senha</label>
                    <div class="campo-senha">
                        <input :type="mostrarConfirmarSenha ? 'text' : 'password'" id="confirmacaosenha" v-model="confirmacaoSenha" required><br><br>
                        <div @click.prevent="toggleMostrarConfirmarSenha" class="btn-view">
                                <img v-if="mostrarConfirmarSenha"
                                    src="@/assets/img/noview.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                                <img v-else
                                    src="@/assets/img/view.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                            </div>
                    </div>

                    <p class="txtregra">A senha deve conter pelo menos:</p>
                    <p :class="verificarSenhaPreenchida().letra">- 1 Letra maiúscula</p>
                    <p :class="verificarSenhaPreenchida().numero">- 1 Número</p>
                    <p :class="verificarSenhaPreenchida().caracterEspecial">
                        - 1 Caractere especial (ex:#?!)
                    </p>

                    <button type="button" class="btn-entrar" 
                    @click="editarSenha"
                    :class="{'btn-disabled': !verificarSenhaPreenchidaCorretamente()}"
                    :disabled="!verificarSenhaPreenchidaCorretamente()"  >ATUALIZAR</button>
                </form>
              
                
            </div>
        </section>

         <section class="sucesso" v-else>
          <img
                  src="@/assets/img/logo_legionarios_branca.svg"
                  alt="Logo Stanley Legionários"
                  class="logo"
          />

          <div class="conteudo-sucesso" data-anima="bottom">
            <h1>SENHA ALTERADA COM <strong>SUCESSO!</strong></h1>

            <router-link to="/home" style="text-decoration: none;" >
              <button type="button" class="btn-login">IR PARA A HOME</button>
            </router-link>
          </div>
        </section>

<TheFooter />
  </div>
</template>

<script>
import { api } from "@/services.js";
import TheFooter from "@/components/Base/TheFooter.vue";
import MenuMobile from "@/components/Base/MenuMobile.vue";
export default {
  name: "AlterarSenha",
   components: {
    MenuMobile,
    TheFooter
  },
  data() {
    return {
        mostrarConfirmarSenha: false,
        mostrarSenha: false,
        mostrarSenhaAtual: false,
        senhaAtual: "",
        senha: "",
        confirmacaoSenha: "",
        loading: false,
        senhaRedefinida: false,
        sucesso: false,
        notificacao_infos: {
          variant: "info",
          mensagem: "",
          count_timer: 0,
      },
    };
  },
   methods: {

      voltar() {
        this.$router.push("/editar-perfil")
      }, 
       toggleMostrarSenha() {
          this.mostrarSenha = !this.mostrarSenha;
        },
        toggleMostrarConfirmarSenha() {
          this.mostrarConfirmarSenha = !this.mostrarConfirmarSenha;
        },
        toggleMostrarSenhaAtual() {
          this.mostrarSenhaAtual = !this.mostrarSenhaAtual;
        },
        verificarSenhasIguais() {
            return this.senha === this.confirmacaoSenha;
        },
        verificarLetra() {
          const letraRegex = /[a-zA-Z]/;
            const possuiLetra = letraRegex.test(this.senha);
            const possuiLetraMaiuscula = /[A-Z]/.test(this.senha);

            if (possuiLetra && possuiLetraMaiuscula) {
                return "regra-valida";
            } else {
                return "regra-invalida";
            }
        },

        verificarNumero() {
          const numeroRegex = /[0-9]/;
          const possuiNumero = numeroRegex.test(this.senha);
          return possuiNumero ? "regra-valida" : "regra-invalida";
        },

        verificarCaracterEspecial() {
          const caracterEspecialRegex = /[!@#$%^&*(),.?":{}|<>]/;
          const possuiCaracterEspecial = caracterEspecialRegex.test(this.senha);
          return possuiCaracterEspecial ? "regra-valida" : "regra-invalida";
        },

        verificarSenhaPreenchida() {
          const classes = {
            letra: this.verificarLetra(),
            numero: this.verificarNumero(),
            caracterEspecial: this.verificarCaracterEspecial(),
          };

          return classes;
        },

        verificarSenhaPreenchidaCorretamente() {
          const letraRegex = /[a-zA-Z]/;
          const numeroRegex = /[0-9]/;
          const caracterEspecialRegex = /[!@#$%^&*(),.?":{}|<>]/;
          const LetraMaiusculaRegex = /[A-Z]/;

          const possuiLetra = letraRegex.test(this.senha);
          const possuiNumero = numeroRegex.test(this.senha);
          const possuiCaracterEspecial = caracterEspecialRegex.test(this.senha);
          const possuiLetraMaiuscula = LetraMaiusculaRegex.test(this.senha);

          const senhasIguais = this.senha === this.confirmacaoSenha;

          return possuiLetra && possuiNumero && possuiCaracterEspecial && possuiLetraMaiuscula && senhasIguais;
        },

        buscarUsuarios() {
          this.loading = true;
          api
            .get('/usuario/logged')
            .then((resp) => {
              this.usuarioLogado = resp.data;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
            });
        },

        editarSenha() {
          this.loading = true;
          
          const data = {
              senha: this.senhaAtual,
              novaSenha: this.senha
          };

          api
                  .patch("/usuario/nova-senha", data)
                      .then((response) => {
                      console.log(response.data);
                      this.sucesso = true;
                  })
                  .catch((error) => {
                      console.error(error);

                      this.notificacao_infos = {
                        variant: "error",
                        mensagem: error.response
                        ? error.response.data.errors[0]?.message
                        : "Algo deu errado na requisição",
                        count_timer: 5,
                    };

                     window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });

                  })
                  .finally(() => {
                      this.loading = false;
                  });
          
      }
  },
  mounted() {
    this.buscarUsuarios();
  }
};
</script>

<style scoped>

  .view{
    margin-top: 15px;
  }

  .btn-entrar {
    margin-bottom: 70px !important;
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    padding: 10px 50px;
    margin: 30px auto; 
    display: block;
    text-align: center;
    width: 300px;
    height: 50px;
  }

  .btn-entrar:hover {
    background-color: #00b6d5;
  }

    .confirmar-senha, .txtregra, .labelsenha {
      margin-top: 20px !important;
    }

    .regra-valida {
      margin-bottom: 0px !important;
      color: #00abc8;
    }

    .regra-invalida {
      margin-bottom: 0px !important;
      color: #e20000;
    }

    .btn-disabled {
      background-color: #03a5c14a !important;
      cursor: auto;
    }

    .anterior {
        background-color: transparent;
        border: none;
    }

    .seta {
        width: 14px;
        margin-right: 15px;
        cursor: pointer;
    }

    .voltar {
      padding: 0px !important;
      display: flex;
      margin: 15px 0px 30px 0px;
    }

    .voltar span {
        color: #52575a;
        font-weight: 200;
    }

    .voltar p {
        margin-bottom: 0px;
        line-height: 17px;
        color: #232527;
        font-size: 14px;
        font-family: "klavika-web", sans-serif;
        font-weight: 700;
    }

    .logo-rodape {
        margin: 0 auto;
        padding-top: 80px;
    }


    label {
        font-family: "klavika bold";
        color: #232527;
        font-size: 14px;
    }

    .formulario {
        width: 320px;
        margin: 0 auto;
        padding-top: 50px;
    }

  .btn-mobile {
    padding: 5px !important;

    right: 15px;
    border: none;
    background-color: transparent;
  }

  .btn-mobile:hover,  
  .btn-mobile:focus,  
  .btn-mobile:active, .side-mob .btn:active {
    border: none !important;
    background-color: transparent !important;
  }

  .logo-mobile {
    width: 200px;
    top: 35px;
    left: 20px;
  }

  .menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
  }

  .nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
  }

  .nav-item a:hover {
    color: #D4D1C4;
  }

  .active a:after {
    content: '';
    display: inline-block;
    width: 8px; 
    height: 8px; 
    border-radius: 50%;
    background-color: #FF681E; 
    margin-left: 12px;
    position: relative;
    top: -3px;
  }

  .active a {
    color: #D4D1C4 !important;
  }

  .mob {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
  }

  .copyright {
    margin-top: 20px;
  }

  .btn-avancar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 300px;
    height: 48px;
  }

  .btn-avancar:hover {
    background-color: #00b6d5;
  }

  input {
    padding: 5px;
  }

  .sucesso {
    height: 100vh;
    background-color: #D4D1C4;
}

.sucesso h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
  line-height: 35px;
  margin-bottom: 20px;
}

.sucesso strong {
  color: #FFFFFF;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.sucesso p {
  color: #FFFFFF;
  font-size: 16px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.conteudo-sucesso {
    width: 350px;
    margin: 0 auto;
    padding-top: 120px;
}

.btn-login {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 350px;
  height: 48px;
  margin: 30px auto;
  display: block;
}

.btn-login:hover {
  background-color: #00b6d5;
}

.sucesso {
    background-image: url('@/assets/img/cadastro_prod_desk.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 990px) {

  .logo-mobile {
    width: 150px;
  }
  
  .copyright {
    margin-bottom: 30px;
  }

  .btn-avancar {
    width: 100%;
  }

  .form-login, .btn-entrar {
    width: 100%;
  }

  .logo-rodape {
    padding-top: 40px;
  }
}
</style>