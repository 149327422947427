<template>
    <div>
      <div class="loading_global" v-if="loading">
        <span class="loader"></span>
      </div>
      <section class="sucesso" v-if="hash && emailBase64">
          <img
                  src="@/assets/img/logo_legionarios_preto.svg"
                  alt="Logo Stanley Legionários"
                  class="logo"
          />
          <div class="conteudo-sucesso" data-anima="bottom">
            <h1>SUA CONTA FOI ATIVADA COM <strong>SUCESSO!</strong></h1>
            <p>Realize o login para acessar os dados de sua conta.</p>
            <button type="button" class="btn-login" @click="irLogin">ACESSAR</button>
          </div>
      </section>
    </div>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "ContaAtivada",
  data() {
    return {
      loading: false,
      hash: "",
      emailBase64: "",

    };
  },
  methods: {
    irLogin(){
      const url = '/login?e=' + this.emailBase64;

      this.$router.push(url);
    },
    ativarConta(hash) {
      const data = {
        hash
      }
      this.loading = true;
      api
        .post(`/usuario/ativar-conta`, data)
        .then((resp) => {
          console.log(resp);
          this.emailBase64 = btoa(resp.data.email);
        })
        .catch((error) => {
          console.log(error);
          this.$router.push("/login");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.hash = this.$route.query.q;
    if(this.hash){
      this.ativarConta(this.hash);
    } else {
      this.$router.push("/login");
    }
    
  },
};
</script>

<style scoped>
.sucesso {
    height: 100vh;
    background-color: #D4D1C4;
}

.sucesso h1 {
  color: #000;
  font-size: 30px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
  line-height: 35px;
  margin-bottom: 20px;
  text-align: center;
}

.sucesso p {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.sucesso strong {
  color: #000;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.conteudo-sucesso {
    width: 300px;
    margin: 0 auto;
    padding-top: 120px;
}

.btn-login {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 300px;
  height: 48px;
  margin: 30px auto;
  display: block;
}

.btn-login:hover {
  background-color: #00b6d5;
}

@media (max-width: 990px) {
  
  .sucesso {
    background-image: url('@/assets/img/bg-sucesso.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .logo {
    padding-top: 40px;
    margin: 0px 30px;
  }

  .conteudo-sucesso {
    padding: 0px 30px;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }

  .btn-login {
    margin: 30px 0px;
  }

  .sucesso p, .sucesso h1, .sucesso strong {
    color: white;
  }
}
</style>