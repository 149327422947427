<template>
    <div>
        <div class="loading_global" v-if="loading">
            <span class="loader"></span>
        </div>
       
        <div v-else>
            <section class="start" v-if="!cadastroRedeSocial">
                <div class="video-container">
                    <video autoplay muted loop class="video desk">
                        <source src="@/assets/videos/WIDE_GERAL.mp4" type="video/mp4">
                    </video>

                    <video autoplay muted loop class="video mob" playsinline>
                        <source src="@/assets/videos/GERAL.mp4" type="video/mp4">
                    </video>
                </div>

                <div class="container">
                <div class="informacoes">
                    <img src="@/assets/img/logo_legionarios_branca.svg" alt="Logo Stanley Legionários" class="logo-item"/>
                        
                    <p>Nós não seguimos: desviamos da trilha para navegar até novas descobertas. 
                    No fundo do nosso DNA, acreditamos que a originalidade é o caminho para um futuro melhor.</p>
                    <router-link to="/cadastro" class="btn-personalizar" style="text-decoration: none;" data-anima="bottom">
                        <button class="junte">JUNTE-SE A NÓS</button>
                    </router-link>
                </div>
                </div>

                <div class="topo-menu">
                    <div class="d-flex container topo">
                        <img
                            src="@/assets/img/logo-stanley-start.svg"
                            alt="Logo Stanley Legionários"
                            class="logo-topo"
                        />
                        
                        <router-link to="/login" style="text-decoration: none; color: inherit" data-anima="bottom">
                            <button class="entrar">ENTRAR</button>
                        </router-link>
                    </div>
                </div>
            </section>

            <section v-else class="container">

            <div class="loading_global" v-if="loading">
            <span class="loader"></span>
            </div>

            <div class="formulario">
                    <img
                        src="@/assets/img/logo_legionarios_preto.svg"
                        alt="Logo Stanley Legionários"
                        class="logo"
                    />
                <div class="titulo-perfil">
                    <h2>COMPLETE <strong>SEU CADASTRO</strong></h2>
                </div>

                <form @submit.prevent="editarPerfil" v-if="!loading">
                    
                    <label for="cpf">CPF</label>
                        <the-mask
                            type="text"
                            id="cpf"
                            name="cpf"
                            v-model="usuarioLogado.cpf"
                            :mask="'###.###.###-##'"
                            :masked="false"
                            inputmode="numeric"
                            required
                            />

                        <label for="celular">Celular</label>
                        <the-mask
                            type="text"
                            id="celular"
                            name="celular"
                            v-model="usuarioLogado.celular"
                            :mask="'(##) #####-####'"
                            inputmode="numeric"
                            required
                            :masked="false"
                            /> 

                            <label for="data">Data de nascimento:</label>
                            <input type="date" id="data" name="data" v-model="usuarioLogado.dataNascimento" required><br><br>
                    
                    <div class="box-atualizar">
                        <button type="submit" class="btn-avancar margin-btn">FINALIZAR</button>
                    </div>
                </form>  
            <TheFooter />
            </div>

            </section>

            <section class="sucesso" v-if="sucesso">
                <img
                        src="@/assets/img/logo_legionarios_branca.svg"
                        alt="Logo Stanley Legionários"
                        class="logo"
                />

                <div class="conteudo-sucesso" data-anima="bottom">
                <h1>PERFIL CADASTRADO COM <strong>SUCESSO!</strong></h1>

                <router-link to="/home" style="text-decoration: none;" >
                    <button type="button" class="btn-login">IR PARA A HOME</button>
                </router-link>
                </div>
            </section>

            <Beneficios />
            
            <TheFooter />
        </div>
    </div>
</template>

<script>
import Beneficios from "@/components/Beneficios.vue";
import TheFooter from "@/components/Base/TheFooter.vue";
import { api } from "@/services.js";
export default {
 name: "Start",
 components: {
  Beneficios,
  TheFooter
  },
 data() {
    return {
      dadosGoogle: [],
      loading: false,
      token: "",
      tokenFace: "",
      dadosFace: [],
      cadastroRedeSocial: false,
      sucesso: false,
      usuarioLogado: []
    };
  },
  mounted() {

    if (window.localStorage.token) {
        this.$router.push({ path: "/home"});
    } 

    const rParam = this.$route.query.r;
    this.$store.commit('SET_R_PARAM', rParam);

    const hashParams = window.location.hash.substring(1);
    const params = new URLSearchParams(hashParams);
    
    const accessToken = params.get('access_token');
    const idToken = params.get('id_token');
    const tokenFace = params.get('long_lived_token');


    if(idToken) {
        this.getUserInfo(accessToken, idToken);
    } else if (accessToken) {
        this.getUserInfoFace(accessToken);
    } else {
        this.getUserInfoFace(tokenFace);
    }
  },
  methods: {
      getUserInfo(accessToken, idToken) {
        this.loading = true;
        fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
        })
        .then(response => response.json())
        .then(data => {
            this.dadosGoogle = data;
            this.provider = "google";
            const dataToSend = {
                    email: this.dadosGoogle.email,
                    provider: this.provider,
                    token: idToken,
                    nome: this.dadosGoogle.given_name
            };

            this.enviarDados(dataToSend);

        })
        .catch(error => {
            console.error('Erro ao obter informações do usuário:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getUserInfoFace(TokenFace) {
        
        this.loading = true;
        fetch(`https://graph.facebook.com/v19.0/me?fields=name,email,picture&access_token=${TokenFace}`)
            .then(response => response.json())
            .then(data => {
                this.dadosFace = data;
                this.provider = "facebook";

                const dataFace = {
                    email: this.dadosFace.email,
                    provider: this.provider,
                    token: TokenFace,
                    nome: this.dadosFace.name,
                };
                this.enviarDadosFace(dataFace);
                })

            .catch(error => {
                console.error('Erro ao obter informações do usuário:', error);
            })
            .finally(() => {
                this.loading = false;
            });
    },

    enviarDados(data) {
        this.loading = true;
        api
                .post(`/auth/social-login`, data)
                    .then((response) => {
                        this.token = response.data.token.token;
                        window.localStorage.token = this.token;
                        this.$router.push("/novo-registro");
                })
                .catch((error) => {
                    console.error(error);
                })
                 .finally(() => {
                    this.loading = false;
                });
    },

    enviarDadosFace(dataFace) {
        this.loading = true;
        api
                .post(`/auth/social-login`, dataFace)
                    .then((response) => {
                        
                        this.tokenFace = response.data.token.token;
                        window.localStorage.token = this.tokenFace;
                        this.$router.push("/novo-registro");
                })
                .catch((error) => {
                    console.error(error);
                })
                 .finally(() => {
                    this.loading = false;
                });
    },

  },

};
</script>

<style scoped>
.start {
    height: 100vh;
    background-color: #52575A;
}

.topo-menu {
    position: absolute;
    top: 0;
    width: 100% !important;
}

.topo {
    justify-content: space-between;
    padding-top: 40px;
}

.logo-item {
    width: 560px;
}

.start button {
    box-shadow: 2px 3px 9px #00000033;
    border: none;
    height: 40px;
    background-color: #FF681E;
    width: 160px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "klavika-web", sans-serif;
    font-weight: 200;
    letter-spacing: 1px;
    font-size: 16px;
}

.start button:hover {
  background-color: #f05b11;
}

.junte {
    width: 370px !important;
    height: 67px !important;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100vh; 
}

.informacoes {
    bottom: 100px;
    position: absolute !important;
 }

  .informacoes p {
    margin-top: 20px;
    width: 560px;
    font-size: 18px;
    color: #FFFFFF;
    font-family: "klavika light";
    margin-bottom: 25px;
  }

  .logo-footer {
    width: 150px;
}

.footer {
    background-color: #232527;
    padding: 40px 0px;
}

.footer img {
    margin: 0 auto;
    padding-bottom: 20px;
}

.copyright {
    color: #fff;
}

@media (min-width: 990px) { 
    .mob {
      display: none;
    }

    video.desk {
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }
}

 @media (max-width: 989px) { 

    .video::-webkit-media-controls {
        display: none !important;
    }

    .video::-webkit-media-controls-enclosure {
        display: none !important;
    }

    .video::-webkit-media-controls-panel {
        display: none !important;
    }

    .logo-topo {
        width: 120px !important;
    }

    .informacoes h1 {
      font-size: 60px;
      letter-spacing: 2px;
    }

    .informacoes p {
      font-size: 16px;
      margin-bottom: 15px;
      width: 100%;
      margin-top: 15px;
      line-height: 20px;
    }

    .video.mob {
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }

    .desk {
      display: none;
    }

    .topo {
        padding: 25px 25px 0px 25px;
    }

    .logo-item, .junte {
        width: 100% !important;
    }

    .entrar {
        width: 120px !important;
    }

    .informacoes {
        padding: 0px 25px 0px 10px;
        bottom: 60px;
    }

}

</style>