<template>
  <div>
     <!-- <header data-anima="top" id="menu-header" class="fixed-top fundo">
              <b-navbar toggleable="lg" class="bg-navbar container">
                <b-navbar-brand href="/home">
                  <img
                      src="@/assets/img/logo_legionarios_branca.svg"
                      alt="Logo Stanley Legionários"
                      class="logoStart"
                  />
                </b-navbar-brand>

                <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav>
                    <b-nav-item :class="{ 'active': currentRoute === '/home' }" href="/stanley/007_portal_legionarios_front/home">INÍCIO</b-nav-item>
                    <b-nav-item :class="{ 'active': currentRoute === '/novo-produto' }" href="/stanley/007_portal_legionarios_front/novo-produto">NOVO PRODUTO</b-nav-item>
                    <b-nav-item :class="{ 'active': currentRoute === '/editar-perfil' }" href="/stanley/007_portal_legionarios_front/editar-perfil">EDITAR PERFIL</b-nav-item>
                  </b-navbar-nav>
                </b-collapse>

                <a href="" style="text-decoration: none; color: inherit" 
                  data-anima="bottom" class="btn-sair" @click.prevent="deslogarUsuario">
                  <img
                      src="@/assets/img/sair.svg"
                      alt="Icone de sair"
                      class="icone-sair"
                  />
                    SAIR
                </a>
              </b-navbar>
      </header>-->

      <section class="container">
        <MenuMobile />
      </section>
  </div>
</template>

<script>
import MenuMobile from "@/components/Base/MenuMobile.vue";
export default {
  name: "Menu",
  components: {
    MenuMobile,
  },
  created() {
    this.currentRoute = this.$route.path; 
  },
  methods: {
    deslogarUsuario() {
      this.$store.dispatch('deslogarUsuario');
      this.$router.push('/');
    },
 },
};
</script>

<style scoped>

.fundo {
  background: transparent linear-gradient(180deg, #232527 0%, #23252700 100%) 0% 0% no-repeat padding-box !important;
}
.active a {
  color: #D4D1C4 !important;
}

.logoStart {
    width: 200px;
    margin-right: 40px;
}

.btn-sair {
    border: 2px solid #FF681E;
    text-decoration: none;
    color: white !important;
    text-transform: uppercase;
    padding: 8px 13px 4px 13px;
    background-color: transparent;
    font-family: "klavika-web", sans-serif;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.btn-sair:hover {
  background-color: #FF681E;
}

.nav-item a {
  color: #FFFFFF;
  font-size: 20px;
  margin-top: 5px;
  margin-right: 50px;
  font-family: "klavika light";
  font-style: normal;
  font-weight: 300;
}

.nav-item a:hover {
  color: #D4D1C4;
}

.icone-sair {
    margin: 0 auto;
    width: 15px;
    padding-bottom: 5px;
}

</style>