<template>
  <div class="background">

    <div class="loading_global" v-if="loading">
        <span class="loader"></span>
    </div>
    <section v-else>
      <div class="container">
        <MenuMobile />
        <section class="indique" >
            <div class="container box-indique">
                <h2 class="h-titulo">INDIQUE O LEGIONÁRIOS <br><strong>E GANHE!</strong></h2>
                <p class="txt-compartilhar">Copie o link abaixo e compartilhe. Acumule indicações e troque por descontos de até 30% OFF.</p>

                <div class="copiar">
                    <b-input-group class="mt-3">
                        <b-form-input v-model="codigos.url"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="info" @click="copiarTexto">COPIAR</b-button>
                        </b-input-group-append>
                    </b-input-group>

                </div>

                <div class="box-redes">
                    <p>Compartilhar:</p>

                    <div class="redes-sociais d-flex justify-content-between">
                        <div class="d-flex icones">
                            <a :href="'mailto:?subject=Portal Legionários | Link de indicação&body=' + encodeURIComponent('Olá! Você é meu convidado especial para participar do Portal Legionários, o hub de fãs da Stanley onde você consegue cadastrar seus produtos, completar missões e resgatar benefícios e cupons de descontos exclusivos. É simples de participar: Basta fazer seu cadastro no link a seguir ' + codigos.url + ' e validar sua conta no e-mail. Junte-se a nós.')"
                                target="_blank">
                                <img src="@/assets/img/email.svg" alt="Ícone email"/>
                            </a>

                            <img src="@/assets/img/instagram.svg" alt="Ícone instagram" @click="openModal()" class="icone-instagram" />
                           
                            <a :href="'https://api.whatsapp.com/send?text=Olá, tudo bem? Você é meu convidado especial para participar do Portal Legionários, o hub de fãs da Stanley onde você consegue cadastrar seus produtos, completar missões e resgatar benefícios e cupons de descontos exclusivos.%0A%0AÉ simples de participar: Basta fazer seu cadastro no link a seguir ' + codigos.url + ' e validar sua conta no e-mail.%0A%0AJunte-se a nós para aproveitar todas as vantagens e oportunidades exclusivas'" target="_blank">
                                <img src="@/assets/img/wpp.svg" alt="Ícone whatsapp"/> 
                            </a>

                        </div> 

                        <div class="selo d-flex">
                            <p class="qtd-ind">{{ indicacoes.confirmados }}</p>
                            <p class="concluido">INDICAÇÕES CONCLUÍDAS</p>
                            <img src="@/assets/img/share.svg" alt="Ícone de compartilhar"/>
                        </div>  
                    </div>

                     <b-modal id="modal-instagram" hide-footer @shown="triggerResize">
                        <carousel :perPage="3" :navigation-enabled="true" class="verdesktop" ref="carouselDesktop" >
                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-1.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>BAIXE A IMAGEM DE DIVULGAÇÃO</h3>

                                        <img src="@/assets/img/passos/1.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                          <button type="button" class="btn-entrar" @click="baixarImagem()" >BAIXAR
                                          </button>
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-2.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>ABRA O INSTAGRAM PELO CELULAR</h3>

                                        <img src="@/assets/img/passos/1.5.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-3.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>NOS STORIES CLIQUE NO BOTÃO DE ADESIVOS</h3>

                                        <img src="@/assets/img/passos/2.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-4.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>SELECIONE O ADESIVO DE LINK</h3>

                                        <img src="@/assets/img/passos/link3.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-5.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>COLE O SEU LINK PERSONALIZADO</h3>

                                        <img src="@/assets/img/passos/4.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-6.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>AGORA É SÓ POSTAR!</h3>

                                        <img src="@/assets/img/passos/5.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                            <button type="button" class="btn-entrar" @click="irInstagram()">IR PARA O INSTAGRAM
                                          </button>
                                     </div>
                                </slide>
                        </carousel>

                         <carousel :perPage="1" :navigation-enabled="true" class="vermobile" ref="carouselMobile">
                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-1.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>BAIXE A IMAGEM DE DIVULGAÇÃO</h3>

                                        <img src="@/assets/img/passos/1.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                          <button type="button" class="btn-entrar" @click="baixarImagem()" >BAIXAR
                                          </button>
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-2.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>ABRA O INSTAGRAM PELO CELULAR</h3>

                                        <img src="@/assets/img/passos/1.5.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-3.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>NOS STORIES CLIQUE NO BOTÃO DE ADESIVOS</h3>

                                        <img src="@/assets/img/passos/2.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-4.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>SELECIONE O ADESIVO DE LINK</h3>

                                        <img src="@/assets/img/passos/link3.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-5.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>COLE O SEU LINK PERSONALIZADO</h3>

                                        <img src="@/assets/img/passos/4.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-6.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>AGORA É SÓ POSTAR!</h3>

                                        <img src="@/assets/img/passos/5.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                            <button type="button" class="btn-entrar" @click="irInstagram()">IR PARA O INSTAGRAM
                                          </button>
                                     </div>
                                </slide>
                        </carousel>                      
                      </b-modal>
    
                </div>
                <h2 class="visao">VISÃO GERAL<br> DE INDICAÇÕES</h2>
                <p class="txt-visao">As pessoas que você indicou e aquelas que se inscreveram mas estão pendentes de confirmação</p>

                <div class="tab-emails">
                  <b-tabs content-class="mt-3">
                    <b-tab active>
                      <template v-slot:title>
                        <span class="title-static">CONFIRMADO: </span>
                        <span class="title-dynamic">{{ indicacoes.confirmados }}</span>
                      </template>
                         <ul v-if="indicacoes.confirmados">
                            <li v-for="email in indicacoes.emailsConfirmados" :key="email">
                              {{ email }}
                            </li>
                        </ul>
                        <div v-else>
                          <p class="msg-pendente">Nenhum email confirmado</p>
                        </div>
                    </b-tab>
                    <b-tab>
                       <template v-slot:title>
                          <span class="title-static">PENDENTES: </span>
                          <span class="title-dynamic">{{ indicacoes.pendentes }}</span>
                        </template>
                        <ul v-if="indicacoes.pendentes">
                          <li v-for="email in indicacoes.emailsPendentes" :key="email">
                            {{ email }}
                          </li>
                        </ul>
                        <div v-else>
                          <p class="msg-pendente">Nenhum email pendente</p>
                        </div>
                    </b-tab>
                  </b-tabs>
                </div>

                <h2 class="visao">POR QUE <br> COMPARTILHAR O LINK?</h2>
                <p class="txt-visao">Compartilhando seu link, você acumula indicações e pode trocar por benefícios nas suas próximas compras. Não há limite para quantas pessoas você pode indicar, então quanto mais você compartilha, mais você ganha!</p>

                <div v-if="etapa === 1" data-anima="right">
                    <div class="d-flex etapa">
                        <div class="etapa-1 box-etapa">
                            <div class="d-flex titulo-indique desktop">
                                <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                                <h2>1</h2>
                              <h3>COPIE O CÓDIGO</h3>
                            </div>

                            <div class="mobile">
                                <div>
                                  <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                                  <h2>1</h2>
                                </div>
                                <h3>COPIE O CÓDIGO</h3>
                            </div>

                            <p class="txt-info">Aqui nessa mesma página, você encontrará um código exclusivo que identifica todas as suas indicações.</p>
                            
                            <div class="d-flex botao">
                              <button @click="numeroEtapa" class="btn-voltar">
                                 <img src="@/assets/img/seta-esquerda.png" alt="Ícone da stanley"/>
                              </button>
                              <p class="numetapa">1/3</p>
                              <button @click="proximaEtapa" class="btn-proximo">
                                <img src="@/assets/img/seta-direita.png" alt="Ícone da stanley"/>
                              </button>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/cel1.png" alt="Ícone da stanley" class="img-passo"/>
                        </div>
                    </div>
                    
                </div>

                <div v-if="etapa === 2" data-anima="right">

                  <div class="d-flex etapa">
                        <div class="box-etapa">
                            <div class="d-flex titulo-indique desktop">
                              <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                              <h2>2</h2>
                              <h3>COMPARTILHE SEU CÓDIGO</h3>
                            </div>

                            <div class="mobile">
                              <div>
                                <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                                <h2>2</h2>
                              </div>
                              <h3>COMPARTILHE SEU CÓDIGO</h3>
                            </div>

                            <p class="txt-info-etapa">Compartilhe o código para seus amigos se cadastrem no nosso Portal. Lembrando que quanto mais indicar, mais pontos você pode acumular.</p>

                            <div class="d-flex botao">
                              <button @click="anteriorEtapa" class="btn-voltar">
                                 <img src="@/assets/img/seta-esquerda.png" alt="Ícone da stanley"/>
                              </button>
                              <p class="numetapa">2/3</p>
                              <button @click="proximaEtapa" class="btn-proximo">
                                <img src="@/assets/img/seta-direita.png" alt="Ícone da stanley"/>
                              </button>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/cel2.png" alt="Ícone da stanley" class="img-passo"/>
                        </div>
                    </div>

                  
                </div>
                    
                <div v-if="etapa === 3" data-anima="right">
                    <div class="d-flex etapa">
                        <div class="box-etapa">
                            <div class="d-flex titulo-indique desktop">
                              <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                              <h2>3</h2>
                              <h3>LIBERE BENEFÍCIOS</h3>
                            </div>

                            <div class="mobile">
                              <div>
                                <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                                <h2>3</h2>
                              </div>
                              <h3>LIBERE BENEFÍCIOS</h3>
                            </div>

                            <p class="txt-info-etapa">Quando seu indicado se cadastra com seu link, automaticamente completa missões e pode trocar por diversos benefícios.</p>

                           <div class="d-flex botao">
                              <button @click="anteriorEtapa" class="btn-voltar">
                                 <img src="@/assets/img/seta-esquerda.png" alt="Ícone da stanley"/>
                              </button>
                              <p class="numetapa">3/3</p>
                              <button @click="numeroEtapa" class="btn-proximo">
                                <img src="@/assets/img/seta-direita.png" alt="Ícone da stanley"/>
                              </button>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/cel3.png" alt="Ícone da stanley" class="img-passo"/>
                        </div>
                    </div>
                </div>
                
            </div>
          
        </section>
      </div>
        
      <TheFooter />
    </section>
                

  </div>
</template>

<script>
import { api } from "@/services.js";
import TheFooter from "@/components/Base/TheFooter.vue";
import MenuMobile from "@/components/Base/MenuMobile.vue";
export default {
  name: "Indicacoes",
  components: {
    MenuMobile,
    TheFooter
  },

  data() {
    return {
      indicacoes: [],
      codigos: [],
      loading: false,
      etapa: 1,
      totalEtapas: 3,
    };
 },
 methods: {

  baixarImagem() {
      const url = 'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/stories_indique.png';
      window.open(url, '_blank');
    },

    irInstagram() {
        window.open("https://www.instagram.com", "_blank")
    },

    triggerResize() {
      window.dispatchEvent(new Event('resize'));
    },

    openModal() {
        this.$bvModal.show('modal-instagram');
    },

  numeroEtapa() {
    if (this.etapa == 1) {
      this.etapa = 3;
    } else if (this.etapa == 3) {
      this.etapa = 1;
    }
  },

  voltar() {
        this.$router.push("/home")
  }, 

   proximaEtapa() {
      if (this.etapa < this.totalEtapas) {
        this.etapa++;
      }
    },
    anteriorEtapa() {
      if (this.etapa > 1) {
        this.etapa--;
      }
    },

    copiarTexto() {
      const inputElement = document.querySelector('.copiar input');
      inputElement.select();
      document.execCommand('copy');
      inputElement.focus();
    },

     buscarIndicacoes() {
        this.loading = true;
        api
          .get('/indicacao')
          .then((resp) => {
            this.indicacoes = resp.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    buscarCodigo() {
        this.loading = true;
        api
          .get('/indicacao/codigo')
          .then((resp) => {
            this.codigos = resp.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

  },
   mounted() {
    this.buscarIndicacoes();
    this.buscarCodigo();
  },
};
</script>

<style scoped>

.icone-instagram {
    cursor: pointer;
}

.btn-entrar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    padding: 10px 50px;
    margin: 0px auto; 
    display: block;
    text-align: center;
    width: 300px;
    height: 50px;
    margin-top: 20px !important;
  }

  .btn-entrar:hover {
    background-color: #00b6d5;
  }

.card-passo {
    background-color: white;
    padding: 40px 20px !important;
    border-radius: 15px;
    width: 100%;
    min-height: 518px;
}

.box-voltar {
  padding: 0px !important;
}

.btn-voltar {
    background-color: transparent;
    border: none;
    margin-top: 15px;
    width: 40px;
}

.btn-proximo {
    background-color: transparent;
    border: none;
    margin-top: 15px;
    width: 40px;
}

.btn-proximo img {
  float: right;
}

.background {
    background-color: #708573;
    background-image: url('@/assets/img/DESK_LP.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
}

.titulo-indique {
  align-items: center;
  gap: 10px;
}

.iconepasso {
  width: 70px;
}

.img-passo {
  width: 300px;
}

.etapa {
  justify-content: space-between;
  gap: 40px;
}

.etapa-1 .txt-info {
  width: 300px;
  margin-top: 30px;
  color: #FFFFFF;
  font-size: 18px;
  font-family: "klavika light";
  line-height: 22px;
  margin-top: 50px;
}

.etapa .txt-info-etapa {
  width: 300px;
  margin-top: 30px;
  color: #FFFFFF;
  font-size: 18px;
  font-family: "klavika light";
  line-height: 22px;
}

.etapa h2 {
  font-family: "KNOCKOUT-HTF70";
  color: #D9D8D6;
  font-size: 105px;
}

.etapa h3 {
  color: #FFFFFF;
  font-size: 35px;
  font-family: "klavika medium";
  line-height: 35px;
}
.etapa .box-etapa {
  width: 300px !important;
}

.numetapa {
  text-align: center;
  width: 50px !important;
  color: #FFFFFF;
  font-size: 29px !important;
  font-family: "klavika bold" !important;
  margin-top: 30px;
}

.title-static {
  color: #ffffff !important;
  font-family: "klavika bold" !important;
  font-size: 20px;
}

.title-dynamic {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 12px;
    position: relative;
    top: -5px;
}

.tab-emails {
    margin-bottom: 40px !important;
    margin-top: 40px !important;
}

.tab-emails ul {
  height: 100px;
  overflow: auto;
  padding-left: 0px;
}

.tab-emails li {
  color: #fff;
  font-size: 15px;
  font-family: "klavika light";
  border-bottom: 1px solid #D9D8D6;
  padding-bottom: 8px;
  padding-top: 10px;
}

.indique {
  width: 700px;
  margin: 0 auto;
  padding-top: 20px;
}

.msg-pendente {
  letter-spacing: 1.8px;
  font-family: "klavika light";
  color: #fff;
  font-size: 19px;
  line-height: 22px;
  margin-top: 30px;
}

.selo {
    border: 2px dashed #fff;
    padding: 5px 20px;
    align-items: center;
    width: 300px;
    height: 85px;
    gap: 8px;
}

.selo img {
    width: 45px;
}

.qtd-ind {
    font-family: "KNOCKOUT-HTF70" !important;
    color: #FF681E !important;
    font-size: 70px !important;
    margin-bottom: 0px !important;
}

.concluido {
    margin-bottom: 0px !important;
    color: #fff !important;
    font-family: "KNOCKOUT-HTF70" !important;
    font-size: 27px !important;
    line-height: 30px;
    text-align: center;
}

.icones {
    gap: 15px;
}

.h-titulo {
    text-align: center;
    letter-spacing: 1.52px;
    font-family: "klavika medium";
    color: #fff;
    line-height: 40px;
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: bold;
}

.h-titulo strong {
    color: #fff !important;
}

.indique .txt-compartilhar {
  margin: 0 auto;
   text-align: center;
    letter-spacing: 1.8px;
    font-family: "klavika light";
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
    width: 700px;
}

.txt-visao {
    letter-spacing: 1.8px;
    font-family: "klavika light";
    color: #fff;
    font-size: 19px;
    line-height: 25px;
    margin-bottom: 30px;
}

.copiar {
    margin-top: 300px !important;
    width: 700px;
    margin: 0 auto;
    margin-bottom: 30px !important;
}

.copiar input {
    border-radius: 0px;
    background: #00000087;
    border: 1px solid white;
    font-size: 25px;
    color: white;
    padding: 10px 20px;
}

.copiar .btn {
    border-radius: 0px;
    height: 56px;
    width: 150px;
    background-color: #FF681E;
    border: none;
}

.copiar .btn:hover {
    transform: none;
    background-color: #f05b11;
}

.box-redes {
    width: 700px;
    margin: 0 auto;
}

.box-redes p {
    letter-spacing: 1.8px;
    font-family: "klavika light";
    color: #fff;
    font-size: 19px;
    margin-bottom: 0px;
}

.redes-sociais {
    gap: 15px;
    align-items: end;
}


.logo-rodape {
  margin: 0 auto;
  padding-top: 80px;
}

.copyright {
  margin-top: 20px;
}

.visao {
  color: #fff;
  font-family: "klavika bold";
  font-size: 33px;
  margin-top: 30px;
  line-height: 36px;
}

.carousel-item {
    width: 300px;
    background: rgb(171, 171, 171) !important;
}


@media (min-width: 991px) {
    .vermobile {
        display: none;
    }
}

@media (max-width: 990px) {

  .card-passo {
    padding: 40px 0px !important;
  }

  .verdesktop {
    display: none;
  }

  .etapa {
    display: block !important;
    gap: 0px;
  }

  .etapa .txt-info-etapa, .etapa .txt-info {
    text-align: center;
    width: 100% !important;
    margin-top: 0px!important;
  }

  .etapa .box-etapa {
    justify-content: center;
    width: 100%!important;
  }

  .botao {
    justify-content: center;
  }

  .numetapa {
    margin-top: 13px !important;
    margin-bottom: 0px !important;
  }

  .img-passo {
    padding-top: 20px !important;
    margin: 0 auto;
  }

  .copyright {
    margin-bottom: 30px;
  }

  .logo-rodape {
    margin: 0 auto;
    padding-top: 80px;
  }

  .indique, .box-redes, .copiar, 
  .indique .txt-compartilhar, .tab-emails {
    width: 100% !important;
  }

  .indique .txt-compartilhar {
      font-size: 15px;
      line-height: 18px;
  }

  .box-indique {
    padding: 0px;
  }

  .h-titulo {
    line-height: 30px;
    font-size: 25px;
    margin-bottom: 10px;
  }

  .copiar .btn {
    height: 50px;
    width: 100px;
  }

  .copiar input {
    font-size: 15px;
  }

  .redes-sociais {
    display: block !important;
  }

  .icones {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
  }

  .box-redes p {
    text-align: center;
  }

  .selo {
      width: 100%;
      gap: 25px;
  }

  .title-static {
    font-size: 13px;
  }

  .background {
        background-size: contain;
        background-image: url('@/assets/img/MOB_LP.png');
  }

  .copiar {
    margin-top: 250px !important;
  }

  .desktop {
    display: none !important;
  }

  .mobile div {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .etapa h3 {
    text-align: center;
  }
}

@media (min-width: 991px) {
  .mobile {
    display: none !important;
  }
}

.mob {
  padding-top: 20px;
  margin-top: 0px !important;
}
</style>