<template>
  <footer>
    <!--<img v-if="usuarioAutenticado" src="@/assets/img/logo-preta.png" alt="Logo Stanley" class="logo-rodape" />-->

    <div class="footer-logado">
         <b-container class="desk">
            <b-row>
              <b-col class="conteudo-tab" cols="12" lg="2"> 
                <p class="titulo-rodape">CATEGORIAS</p>
                <ul>
                  <li><a href="https://www.stanley1913.com.br/bar" target="_blank">Bar</a></li>
                  <li><a href="https://www.stanley1913.com.br/hidratacao" target="_blank">Hidratação</a></li>
                  <li><a href="https://www.stanley1913.com.br/cafe" target="_blank">Café</a></li>
                  <li><a href="https://www.stanley1913.com.br/camping" target="_blank">Camping</a></li>
                  <li><a href="https://www.stanley1913.com.br/mate" target="_blank">Mate</a></li>
                  <li><a href="https://www.stanley1913.com.br/youth" target="_blank">Youth</a></li>
                </ul>
              </b-col>

               <b-col class="conteudo-tab" cols="12" lg="3"> 
                <p class="titulo-rodape">AJUDA</p>
                <ul>
                  <li><a href="https://www.stanley1913.com.br/contato" target="_blank">Central de Atendimento</a></li>
                  <li><a href="https://checkout.stanley1913.com.br/login/Authenticate?returnUrl=https%3A%2F%2Fcheckout.stanley1913.com.br%2FMinhaConta%2FPedido%2F" target="_blank">Seus Pedidos</a></li>
                  <li><a href="https://www.stanley1913.com.br/garantia-vitalicia" target="_blank">Garantia Vitalícia</a></li>
                  <li><a href="https://atendimentostanley.zendesk.com/hc/pt-br/articles/360050074872-Devolu%C3%A7%C3%A3o-por-desist%C3%AAncia-de-compra" target="_blank">Trocas e devoluções</a></li>
                  <li><a href="https://atendimentostanley.zendesk.com/hc/pt-br/sections/360010713671" target="_blank">Política de Privacidade</a></li>
                  <li><a href="https://atendimentostanley.zendesk.com/hc/pt-br/sections/4416015816475" target="_blank">Política de cookies</a></li>
                  <li><a href="https://www.stanley1913.com.br/venda-corporativa" target="_blank">Venda Corporativa</a></li>
                </ul>
              </b-col>

             <b-col class="conteudo-tab" cols="12" lg="2"> 
                <p class="titulo-rodape">SOBRE</p>
                <ul>
                  <li><a href="https://www.stanley1913.com.br/sobrenos" target="_blank">Sobre nós</a></li>
                  <li><a href="https://www.stanley1913.com.br/imprensa" target="_blank">Imprensa</a></li>
                  <li><a href="https://www.stanley1913.com.br/responsabilidade" target="_blank">Responsabilidade</a></li>
                  <li><a href="https://www.stanley1913.com.br/nossas-lojas" target="_blank">Nossas lojas</a></li>
                  <li><a href="https://www.stanley1913.com.br/stanleypravida" target="_blank">#stanleypravida</a></li>
                  <li><a href="https://www.blog.stanley1913.com.br/" target="_blank">Blog</a></li>
                </ul>
              </b-col>


              <b-col class="conteudo-tab" cols="12" lg="5"> 
                <img src="@/assets/img/logo-bear.png" alt="Logo Stanley" class="logo-rodape"/>
              </b-col>

            </b-row>

            <p class="copyright-rodape">© {{ ano_atual }} Stanley - CNPJ: 30.055.933/0006-51 Todos os direitos reservados</p>  
         </b-container>

         <b-container class="mob">
            <div>
              <div class="mb-3">
                <b-button v-b-toggle.my-collapse class="btn-toggle d-flex">CATEGORIAS <img src="@/assets/img/sinal.png" alt="Logo Stanley" /></b-button>
              </div>

               <b-collapse id="my-collapse">
                <b-card>
                    <ul>
                      <li><a href="https://www.stanley1913.com.br/bar" target="_blank">Bar</a></li>
                      <li><a href="https://www.stanley1913.com.br/hidratacao" target="_blank">Hidratação</a></li>
                      <li><a href="https://www.stanley1913.com.br/cafe" target="_blank">Café</a></li>
                      <li><a href="https://www.stanley1913.com.br/camping" target="_blank">Camping</a></li>
                      <li><a href="https://www.stanley1913.com.br/mate" target="_blank">Mate</a></li>
                      <li><a href="https://www.stanley1913.com.br/youth" target="_blank">Youth</a></li>
                    </ul>
                </b-card>
              </b-collapse>

              <div class="mb-3">
                <b-button v-b-toggle.my-collapse2 class="btn-toggle d-flex">AJUDA <img src="@/assets/img/sinal.png" alt="Logo Stanley" /></b-button>
              </div>

              <b-collapse id="my-collapse2">
                <b-card>
                    <ul>
                      <li><a href="https://www.stanley1913.com.br/contato" target="_blank">Central de Atendimento</a></li>
                      <li><a href="https://checkout.stanley1913.com.br/login/Authenticate?returnUrl=https%3A%2F%2Fcheckout.stanley1913.com.br%2FMinhaConta%2FPedido%2F" target="_blank">Seus Pedidos</a></li>
                      <li><a href="https://www.stanley1913.com.br/garantia-vitalicia" target="_blank">Garantia Vitalícia</a></li>
                      <li><a href="https://atendimentostanley.zendesk.com/hc/pt-br/articles/360050074872-Devolu%C3%A7%C3%A3o-por-desist%C3%AAncia-de-compra" target="_blank">Trocas e devoluções</a></li>
                      <li><a href="https://atendimentostanley.zendesk.com/hc/pt-br/sections/360010713671" target="_blank">Política de Privacidade</a></li>
                      <li><a href="https://atendimentostanley.zendesk.com/hc/pt-br/sections/4416015816475" target="_blank">Política de cookies</a></li>
                      <li><a href="https://www.stanley1913.com.br/venda-corporativa" target="_blank">Venda Corporativa</a></li>
                    </ul>
                </b-card>
              </b-collapse>

              <div class="mb-3">
                <b-button v-b-toggle.my-collapse3 class="btn-toggle d-flex">SOBRE <img src="@/assets/img/sinal.png" alt="Logo Stanley" /></b-button>
              </div>

              <b-collapse id="my-collapse3">
                <b-card>
                    <ul>
                      <li><a href="https://www.stanley1913.com.br/sobrenos" target="_blank">Sobre nós</a></li>
                      <li><a href="https://www.stanley1913.com.br/imprensa" target="_blank">Imprensa</a></li>
                      <li><a href="https://www.stanley1913.com.br/responsabilidade" target="_blank">Responsabilidade</a></li>
                      <li><a href="https://www.stanley1913.com.br/nossas-lojas" target="_blank">Nossas lojas</a></li>
                      <li><a href="https://www.stanley1913.com.br/stanleypravida" target="_blank">#stanleypravida</a></li>
                      <li><a href="https://www.blog.stanley1913.com.br/" target="_blank">Blog</a></li>
                    </ul>
                </b-card>
              </b-collapse>

               <b-col class="conteudo-tab" cols="12" lg="5"> 
                <img src="@/assets/img/logo-bear.png" alt="Logo Stanley" class="logo-rodape"/>
              </b-col>

              <p class="copyright-rodape">© {{ ano_atual }} Stanley - CNPJ: 30.055.933/0006-51 Todos os direitos reservados</p>  

            </div>
         </b-container>

    </div>

    <div class="footer-logado footer-infos" v-if="$route.path === '/home' || $route.path === '/indicacoes'">
      <div class="container texto-legal">

         <p>  *Consideramos como indicações bem sucedidas, aqueles usuários que utilizaram o seu link personalizado para se 
          inscrever e completaram o processo de cadastro com sucesso.</p>
      </div>
    </div>

    <!--<div v-else>
      <p class="copyright">© {{ ano_atual }} Stanley - CNPJ: 30.055.933/0006-51 <br/>Todos os direitos reservados</p>  
    </div>  -->
              
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    ano_atual() {
      return new Date().getFullYear();
    },
    usuarioAutenticado() {
      return localStorage.getItem('token') !== null;
    }
  },
};
</script>

<style scoped>

.footer-logado {
  background-color: #232527;
  padding: 30px 0px;
}

.copyright {
    padding-bottom: 20px;
}

.logo-rodape {
    float: right;
}

.titulo-rodape {
   font-family: "klavika bold";
  color: white;
}

.footer-logado ul {
  padding-left: 0px;
}

.footer-logado a {
  color: white;
  text-transform: capitalize;
  text-decoration: none;
  line-height: 30px;
}

.footer-logado a:hover {
  color: #00ABC8;
}

.copyright-rodape {
  color: white;
  margin-top: 40px;
  font-size: 14px;
  margin-bottom: 0px;
}

.texto-legal p {
  color: #8E8E8E;
  font-size: 13px;
  text-align: center;
}

.footer-infos {
  border-top: 1px solid #595959;
}

@media (max-width: 990px) {
  .copyright-rodape {
    text-align: center;
  }
  
  li {
    text-align: center;
  }

  .logo-rodape {
    float: initial;
    margin: 0 auto;
    padding-top: 30px;
  }

  .desk {
    display: none;
  }

  .card {
    background-color: transparent !important;
  }

  .btn-toggle {
    background-color: transparent;
    border:none;
    border-radius: 0px;
    border-bottom: 1px solid white;
    padding: 0px;
    margin: 0 auto;
    text-align: center;
    display: table;
    width: 100%;
    height: 40px;
    gap: 20px;
  }

  .btn-toggle:hover {
    background-color: transparent;
    border-bottom: 1px solid white;
    
  }

  .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    background-color: transparent;
    border-bottom: 1px solid white;
  }

  .footer-logado {
    padding: 30px 10px;
  }

}

@media (min-width: 991px) {
  .mob {
    display: none;
  }
}
</style>