<template>
  <div class="background">

    <div class="loading_global" v-if="loading">
            <span class="loader"></span>
    </div>

    <div v-else>
      <section v-if="!mensagem" class="container">
          <MenuMobile />
            <div class="voltar">
                    <button
                      type="button"
                      @click="voltar"
                      class="anterior">
                      <img
                        src="@/assets/img/left.png"
                        alt="icone seta para esquerda"
                        class="seta"
                      />
                    </button>
                    <p><span>Editar produto</span> <br />Alterar informações</p>
                    
                  </div>
            <div>

              <div class="produtosAgrupados" v-if="produtoSelecionado.produtos && produtoSelecionado.produtos.length > 1"> 
                  <div class="box-revisao">
                    <div class="d-flex ultima-etapa justify-content-start" v-if="produtoSelecionado">
                        <img :src="produtos.imgAlternativa ? produtos.imgAlternativa :  'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/IMG_PROD_GENERICO.png'" alt="Imagem do produto" class="img-selecionada"/>                    
                        <p>{{ produtos.nome }}</p>
                    </div> 

                    <div class="numPedidos">
                      <p>PEDIDOS</p>
                      <span class="title-dynamic">{{produtoSelecionado.produtos.length}}</span>
                    </div>
                  </div>

                  <div class="lista-pedidos">
                    <div class="d-flex item-pedido" v-for="(item, index) in produtosAgrupados" :key="index">
                      <p>{{ formatarDataTransacao(item.dataCompra) }} - {{item.quantidade}} unidade(s)</p>
                      <div class="infos-pedido d-flex">
                        
                        <img 
                             v-if="item.produtoValidado"
                             src="@/assets/img/VERIFICADO.svg"
                              alt="ícone de verificado"
                               class="icone-gift"
                        />

                        <img v-if="item.compradoParaPresente > 0"
                            src="@/assets/img/GIFT.svg"
                            alt="ícone de verificado"
                            class="icone-gift"
                          />

                          <img
                              src="@/assets/img/edit.svg"
                              alt="ícone de editar"
                              class="icone-edit"
                              @click="openModal(item)"
                          />
                      </div>
                      
                    </div>

                  </div>
              </div>

              <div class="form-produto" data-anima="top" v-else>
    
                <form>

                  <Notificacao
                    :infos="notificacao_infos"
                    v-if="notificacao_infos.mensagem"
                  /> 
                    
                    <div class="box-revisao">
                          <div class="d-flex ultima-etapa justify-content-start">
                            <img :src="produtos.imgAlternativa ? produtos.imgAlternativa :  'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/IMG_PROD_GENERICO.png'" alt="Imagem do produto" class="img-selecionada"/>                    
                            <p>{{ produtos.nome }}</p>
                          </div> 
                    </div>

                   <!--<div>
                     <label for="unidade" class="label">UNIDADES:</label>
                      <input type="number" id="unidade" name="unidade" v-model="qtd"/>
                      <button
                        type="button"
                        class="btn-avancar"
                        @click="editarProduto(false)" >
                        ATUALIZAR
                      </button>
                   </div>-->
                </form>

                <div class="alteracoes">
                  <p v-if="!hasValidProducts">Se você não possuí mais esse produto, ou cadastrou errado você pode remover ele da sua lista</p>
                  <button
                      class="btn-remover"
                      v-if="!hasValidProducts"
                      @click="deletarProduto(produtoSelecionado.produtos[0].id)"
                      >
                      REMOVER PRODUTO
                  </button>

                  <p>Se você comprou esse produto para alguém você pode clicar no botão abaixo.</p>
                  <button
                      type="button"
                      @click="editarProduto(true)"
                      class="btn-presente" >
                      COMPREI PARA PRESENTE
                    </button>

                </div>
                
              </div>

              <b-modal id="modal-produtos" ref="modalProdutos" hide-footer>
                  <div>
                      <div class="d-flex ultima-etapa justify-content-start">
                        <img :src="produtos.imgAlternativa ? produtos.imgAlternativa :  'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/IMG_PROD_GENERICO.png'" alt="Imagem do produto" class="img-selecionada"/>                    
                        <p>{{ produtos.nome }}</p>
                      </div> 
                      <p class="informacoes-modal">{{ formatarDataTransacao(dadosProdutos?.dataCompra) }} - {{dadosProdutos.quantidade}} unidade(s)</p>

                      <div class="alteracoes">
                        <p v-if="!hasValidProducts">Se você não possuí mais esse produto, ou cadastrou errado você pode remover ele da sua lista</p>
                        <button
                            class="btn-remover"
                            v-if="!dadosProdutos.produtoValidado"
                            @click="deletarProduto(dadosProdutos.id)"
                            >
                            REMOVER PRODUTO
                        </button>

                        <p>Se você comprou esse produto para alguém você pode clicar no botão abaixo.</p>
                        <button
                            type="button"
                            @click="openModalPresente(dadosProdutos)"
                            class="btn-presente" >
                            COMPREI PARA PRESENTE
                          </button>

                      </div>

                  </div>
              </b-modal>

              <b-modal id="modal-presentes" ref="modalPresentes" hide-footer>
                  <div class="modalPresentes">
                      <div class="d-flex ultima-etapa justify-content-start">
                        <img :src="produtos.imgAlternativa ? produtos.imgAlternativa :  'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/IMG_PROD_GENERICO.png'" alt="Imagem do produto" class="img-selecionada"/>                    
                        <p>{{ produtos.nome }}</p>
                      </div> 
                      <p class="informacoes-modal">{{ formatarDataTransacao(dadosProdutos.dataCompra) }} - {{dadosProdutos.quantidade}} unidade(s)</p>

                      <h3>COMPREI <br>PARA PRESENTE</h3>

                      <div class="d-flex qtd-presentes">
                        <label>QUANTIDADE</label>
                        <select name="select" class="selectQtd" v-model="qtdpresentes"> 
                          <option value="0">0</option>
                          <option :value="index + 1" v-for="(item, index) in dadosProdutos.quantidade" :key="index">{{index + 1}}</option>
                        </select> 
                      </div>

                      <button
                          @click="editarProdutoAgrupado(dadosProdutos.id, dadosProdutos.localCompra, dadosProdutos.quantidade)"
                          type="button"
                          class="btn-avancar" >
                          ATUALIZAR
                        </button>
                  </div>
              </b-modal>

    
        </div>
      </section>

      <section class="sucesso" v-else>
          <img
                  src="@/assets/img/logo_legionarios_branca.svg"
                  alt="Logo Stanley Legionários"
                  class="logo"
          />

          <div class="conteudo-sucesso" data-anima="bottom">
            <h1 v-if="editado">PRODUTO ATUALIZADO COM <strong>SUCESSO!</strong></h1>
            <h1 v-else>PRODUTO REMOVIDO COM <strong>SUCESSO!</strong></h1>
            <p>Você pode ver seus produtos cadastrados em "Minha Coleção".</p>

            <router-link to="/home" style="text-decoration: none;" >
              <button type="button" class="btn-login">IR PARA A HOME</button>
            </router-link>
          </div>
      </section>
      <TheFooter />
    </div>

  </div>
</template>

<script>
import { format } from 'date-fns';
import { api } from "@/services.js";
import TheFooter from "@/components/Base/TheFooter.vue";
import MenuMobile from "@/components/Base/MenuMobile.vue";
export default {
  name: "AlterarEmail",
  components: {
    MenuMobile,
    TheFooter
  },
   data() {
    return {
      produtos: "",
      id: "",
      qtd: "",
      qtdpresentes: "0",
      loading: false,
      mensagem: false,
      dadosProdutos: [],
      produtoSelecionado: [],
      produtosAgrupados: [],
      editado: false,
      notificacao_infos: {
          variant: "info",
          mensagem: "",
          count_timer: 0,
      },
      compradoParaPresenteTotal: ""
    };
  },
  computed: {
    hasValidProducts() {
      if (this.produtoSelecionado && Array.isArray(this.produtoSelecionado.produtos)) {
        return this.produtoSelecionado.produtos.some(produto => produto.produtoValidado);
      } else {
        return false;
      }
    }
  },
  methods: {

     formatarDataTransacao(dataTransacao) {
        if (!dataTransacao) {
          return 'Data inválida';
        }

        const date = new Date(dataTransacao);
        if (isNaN(date.getTime())) {
          return 'Data inválida';
        }

        return format(date, 'dd/MM/yyyy');
      },

      openModal(item) {
        this.dadosProdutos = { ...item };
        this.$bvModal.show('modal-produtos');
      },

      openModalPresente(item) {
        this.dadosProdutos = { ...item };
        this.$bvModal.show('modal-presentes');
        this.$bvModal.hide('modal-produtos');
      },

      voltar() {
        this.$router.push("/home")
      },

      async buscarUsuarios() {
        try {
            this.loading = true;
            const resp = await api.get('/usuario/logged');
            this.usuarioLogado = resp.data;
            this.cpf = this.usuarioLogado.cpf;
        } catch (error) {
            console.log(error);
        } finally {
            this.loading = false;
        }
      },
      async produtosCadastrados() {
        try {
          this.loading = true;
          await this.buscarUsuarios();
          const resp = await api.get(`/produto/cliente?cpf=${this.cpf}`);
          this.dadosProdutos = resp.data;

          this.produtoSelecionado = this.dadosProdutos.find(produto => produto.id === this.produtos.id);
          
          this.produtosAgrupados = this.produtoSelecionado.produtos;

          if (this.produtoSelecionado && this.produtos && this.produtoSelecionado.produtos.length > 0) {
            this.qtd = this.produtoSelecionado.produtos[0].quantidade;
          }

        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },
      buscarProdutoPorId(id) {
        this.loading = true;
        api
          .get(`/produto/${id}`)
          .then((resp) => {
            this.produtos = resp.data;
           
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      deletarProduto(id) {
        this.loading = true;
        api
          .delete(`/produto/cliente/${id}`)
          .then((resp) => {
            console.log(resp);
            this.mensagem = true;
          })
          .catch((error) => {
            console.log(error);

            this.notificacao_infos = {
                        variant: "error",
                        mensagem: error.response
                        ? error.response.data.errors[0]?.message
                        : "Algo deu errado na requisição",
                        count_timer: 5,
                    };

          })
          .finally(() => {
            this.loading = false;
          });
      },
      
      totalCompradoParaPresente(produto) {
        if (!produto.produtos || produto.produtos.length === 0) {
          return 0;
        }
        return produto.produtos.reduce((total, item) => {
          return total + (item.compradoParaPresente || 0);
        }, 0);
      },

      editarProdutoAgrupado (id, localCompra, quantidade) {
        this.loading = true;

        const data = {
            idProduto: id,
            localCompra: localCompra,
            quantidade: quantidade,
            presente: false,
            compradoParaPresente: this.qtdpresentes,
        };

        api
          .put(`/produto/cliente`, data)
          .then((resp) => {
            console.log(resp);
            this.editado = true;
            this.produtosCadastrados();
            
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      editarProduto(presente) {

        if(presente) {
          this.compradoParaPresenteTotal = "1"
        } else {
          this.compradoParaPresenteTotal = this.totalCompradoParaPresente(this.produtoSelecionado);
        }

        this.loading = true;

        const data = {
            idProduto: this.produtoSelecionado.produtos[0].id,
            localCompra: this.produtoSelecionado.produtos[0].localCompra,
            quantidade: "1",
            presente: false,
            compradoParaPresente: this.compradoParaPresenteTotal,
        };

        api
          .put(`/produto/cliente`, data)
          .then((resp) => {
            console.log(resp);
            this.editado = true;
            this.mensagem = true;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
  },
   mounted() {
    this.produtosCadastrados();
    this.id = this.$route.params.id;
    this.buscarProdutoPorId(this.id);
  },

};
</script>

<style scoped>

  .qtd-presentes label {
    color: #232527;
    font-size: 22px;
    font-family: "klavika bold";
  }

  .qtd-presentes select {
    width: 70px;
    padding: 0px 10px;
    height: 45px;
  }

  .qtd-presentes {
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;
    gap: 20px;
  }

  .modalPresentes .btn-avancar {
    margin: auto;
    display: table;
    margin-bottom: 30px;
  }
  
  .modalPresentes h3 {
    color: #FF681E;
    font-size: 37px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    text-align: center;
    margin: auto;
    line-height: 35px;
    margin-top: 20px !important;
  }

  .informacoes-modal {
      color: #000000;
      text-align: center;
      font-size: 20px;
      font-family: "klavika medium";
      text-transform: uppercase;
      margin: auto;
      width: 260px;
      border-bottom: 1px solid black;
      padding-bottom: 10px;
  }

  .w25 {
    margin-left: 24px;
  }

  .infos-pedido {
    gap: 15px;
    align-items: center;
  }

  .item-pedido {
    gap: 15px;
    align-items: center;
    border-bottom: 1px solid #D9D8D6;
    padding-bottom: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .item-pedido p {
    font-family: "klavika light" !important;
    font-size: 16px !important;
    margin-bottom: 0px;
  }

  .numPedidos {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .icone-gift {
      width: 25px;
  }

  .icone-edit {
    width: 25px;
    cursor: pointer;
  }

  .title-dynamic {
      background-color: #D9D8D6;
      padding: 6px 12px;
      border-radius: 50%;
      font-size: 12px;
      position: relative;
      top: -8px;
  }

  .produtosAgrupados {
    min-height: 400px;
    width: 390px;
    margin: 40px auto;
    padding-bottom: 40px;
  }

  .produtosAgrupados p {
    font-family: "klavika bold";
    color: #52575A;
    font-size: 20px;
  }

  form, .alteracoes {
    width: 390px;
    margin: 40px auto;
  }

  label {
    margin-top: 20px;
  }

  .alteracoes p {
    font-family: "klavika light";
    color: #232527;
    letter-spacing: 1.43px;
    font-size: 15px;
  }

  .logo-rodape {
    margin: 0 auto;
    padding-top: 80px;
  }

  .box-revisao label {
    color: #232527;
    font-size: 19px;
    font-family: "klavika bold";
  }

  .img-selecionada {
    width: 140px;
  }

  .ultima-etapa p {
    margin-bottom: 0px;
    font-family: "klavika bold";
    color: #232527;
    font-size: 25px;
    line-height: 28px;
  }

  .ultima-etapa {
    margin-bottom: 20px;
    align-items: center;
  }

  .anterior {
    background-color: transparent;
    border: none;
  }

  .seta {
    width: 14px;
    margin-right: 15px;
    cursor: pointer;
  }

  .voltar {
    display: flex;
    margin: 15px 0px 30px 0px;
  }

  .voltar span {
    color: #52575a;
    font-weight: 200;
  }

  .voltar p {
    margin-bottom: 0px;
    line-height: 17px;
    color: #232527;
    font-size: 14px;
    font-family: "klavika-web", sans-serif;
    font-weight: 700;
  }

.btn-avancar {
    margin-top: 20px;
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 20px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 390px;
    height: 65px;
    font-family: "klavika medium";
  }

  .btn-avancar:hover {
    background-color: #00b6d5;
  }

  .btn-remover {
    font-family: "klavika medium";
    margin-bottom: 50px;
    transition: background-color 0.3s ease;
    background-color: #52575A;
    color: white;
    font-size: 20px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 390px;
    height: 65px;
  }

  .btn-remover:hover {
    background-color: #232527;
  }

  .btn-presente {
    font-family: "klavika medium";
    margin-bottom: 50px;
    transition: background-color 0.3s ease;
    background-color: #6F8473;
    color: white;
    font-size: 20px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 390px;
    height: 65px;
  }

  .btn-presente:hover {
    background-color: #566559;
  }

   .btn-personalizar {
      margin-top: 50px !important;
      transition: background-color 0.3s ease;
      background-color: #00abc8;
      color: white;
      font-size: 20px;
      box-shadow: 2px 3px 9px #00000033;
      cursor: pointer;
      padding: 10px 50px;
      letter-spacing: 0.36px;
      font-family: "klavika-web", sans-serif;
      margin: 0 auto;
      display: table;
    }

    .btn-personalizar:hover {
      background-color: #00b6d5;
    }

    .txthome {
      text-align: center;
    }

    .txtcadastrado {
      margin-bottom: 0px;
      font-family: "klavika-web", sans-serif;
      font-weight: 900;
      font-size: 25px;
      color: #000;
      text-align: center;
      text-transform: uppercase;
      margin-top: 100px;
    
    }

      
.sucesso {
    height: 100vh;
    background-color: #D4D1C4;
}

.sucesso h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
  line-height: 35px;
  margin-bottom: 20px;
}

.sucesso strong {
  color: #FFFFFF;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.sucesso p {
  color: #FFFFFF;
  font-size: 16px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.conteudo-sucesso {
    width: 350px;
    margin: 0 auto;
    padding-top: 120px;
}

.btn-login {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 350px;
  height: 48px;
  margin: 30px auto;
  display: block;
}

.btn-login:hover {
  background-color: #00b6d5;
}

.sucesso {
    background-image: url('@/assets/img/cadastro_prod_desk.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}


  @media (max-width: 990px) {
    form, .alteracoes, .produtosAgrupados,
    .btn-avancar, .btn-presente, .btn-remover {
      width: 100% !important;
    }

    .conteudo-sucesso {
        padding: 0px 10px;
        width: 100%;
        position: absolute;
        bottom: 0px;
    }

    .sucesso {
      background-image: url('@/assets/img/cadastro_prod_mob.jpg');

    }
  }
</style>