import { render, staticRenderFns } from "./PersonalizarPortal.vue?vue&type=template&id=3429ae5e&scoped=true&class=bg-pesquisa"
import script from "./PersonalizarPortal.vue?vue&type=script&lang=js"
export * from "./PersonalizarPortal.vue?vue&type=script&lang=js"
import style0 from "./PersonalizarPortal.vue?vue&type=style&index=0&id=3429ae5e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3429ae5e",
  null
  
)

export default component.exports