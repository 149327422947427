<template>
  <div class="container background">
    <section>
        <MenuMobile />
        <div class="voltar">
                <button type="button" class="anterior" @click="voltar">
                    <img
                        src="@/assets/img/left.png"
                        alt="icone seta para esquerda"
                        class="seta"
                    />
                </button>
                <p><span>Meu Perfil </span> <br />Alterar meu e-mail</p>
            </div>

            <div class="formulario" v-if="!alterado">
              <form>
                        <div class="div-campos">
                          <label for="novoemail">Novo e-mail</label>
                            <input type="email" id="campos" name="campos">  
                            <p class="info-email">O email só será alterado após confirmação do cadastro enviado para o seu email.</p>
                          </div>

                      <div class="box-atualizar">
                        <button type="submit" class="btn-avancar margin-btn" @click="alterarEmail">ATUALIZAR</button>
                      </div>

                      <TheFooter />
              </form>
            </div> 


            <div v-else>
                <div class="txt-alterado" data-anima="bottom">
                  <h1>ALTERAÇÃO DE E-MAIL REALIZADA COM <strong>SUCESSO!</strong></h1>
                  <p>Enviamos um e-mail de confirmação, verifique sua caixa postal.</p>

                  <router-link to="/home" style="text-decoration: none;" >
                    <button type="button" class="btn-login">PÁGINA INICIAL</button>
                  </router-link>
                </div>

                    <TheFooter />

          </div>
  </section>

  </div>
</template>

<script>
import TheFooter from "@/components/Base/TheFooter.vue";
import MenuMobile from "@/components/Base/MenuMobile.vue";
export default {
  name: "AlterarEmail",
  components: {
    MenuMobile,
    TheFooter
  },

  data() {
    return {
      alterado: false,
    };
  },
   methods: {
      voltar() {
        this.$router.push("/editar-perfil")
      },
      alterarEmail() {
        this.alterado = true;
      }
  },
};
</script>

<style scoped>
  .box-atualizar {
      margin-bottom: 30px !important;
  }
    .anterior {
        background-color: transparent;
        border: none;
    }

    .seta {
        width: 14px;
        margin-right: 15px;
        cursor: pointer;
    }

    .voltar {
        display: flex;
        margin: 15px 0px 30px 0px;
    }

    .voltar span {
        color: #52575a;
        font-weight: 200;
    }

    .voltar p {
        margin-bottom: 0px;
        line-height: 17px;
        color: #232527;
        font-size: 14px;
        font-family: "klavika-web", sans-serif;
        font-weight: 700;
    }

    .txt-alterado {
      padding-top: 50px;
      width: 500px;
      margin: 0 auto;
      text-align: center;
    }

    .logo-rodape {
        margin: 0 auto;
        padding-top: 80px;
    }

  .btn-login {
    transition: background-color 0.3s ease;
    background-color: #00ABC8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 300px;
    height: 48px;
    margin: 30px auto;
    display: block;
  }

  .btn-login:hover {
    background-color: #00b6d5;
  }

    .info-email {
        color: #232527;
        font-size: 16px;
        font-family: "klavika light";
        line-height: 18px;
        margin-top: 15px;
    }

    label {
        font-family: "klavika bold";
        color: #232527;
        font-size: 14px;
    }

    .formulario {
        width: 320px;
        margin: 0 auto;
        padding-top: 50px;
    }

  .btn-mobile {
    padding: 5px !important;

    right: 15px;
    border: none;
    background-color: transparent;
  }

  .btn-mobile:hover,  
  .btn-mobile:focus,  
  .btn-mobile:active, .side-mob .btn:active {
    border: none !important;
    background-color: transparent !important;
  }

  .logo-mobile {
    width: 200px;
    top: 35px;
    left: 20px;
  }

  .menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
  }

  .nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
  }

  .nav-item a:hover {
    color: #D4D1C4;
  }

  .active a:after {
    content: '';
    display: inline-block;
    width: 8px; 
    height: 8px; 
    border-radius: 50%;
    background-color: #FF681E; 
    margin-left: 12px;
    position: relative;
    top: -3px;
  }

  .active a {
    color: #D4D1C4 !important;
  }

  .mob {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
  }

  .copyright {
    margin-top: 20px;
  }

  .btn-avancar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 300px;
    height: 48px;
  }

  .btn-avancar:hover {
    background-color: #00b6d5;
  }

  input {
    padding: 5px;
  }

@media (max-width: 990px) {

  .logo-mobile {
    width: 150px;
  }
  
  .copyright {
    margin-bottom: 30px;
  }

  .btn-avancar {
    width: 100%;
  }

  .formulario {
    width: 100%;
    padding-top: 20px;
   }

    .txt-alterado {
      width: 100%;
    }

    .logo-rodape {
        margin: 0 auto;
        padding-top: 80px;
    }
   
}
</style>