<template>

    <section class="login" data-anima="bottom">

        <div class="loading_global" v-if="loading">
            <span class="loader"></span>
        </div>

        <div v-else>
          <router-link to="/home">
            <img
                src="@/assets/img/logo_legionarios_preto.svg"
                alt="Logo Stanley Legionários"
                class="logo"
            />
          </router-link>
            <h1><strong class="strong">ATIVAÇÃO </strong>DE CONTA</h1>
            
            <div class="form-login">
                <Notificacao
                    :infos="notificacao_infos"
                    v-if="notificacao_infos.mensagem"
                />
                <form @submit.prevent="submitReenvio">
                    <label for="email">Endereço de e-mail</label>

                    <input type="email" id="email" name="email" v-model="form.email" required ><br><br>
                  
                    <input type="submit" :disabled="isButtonDisabled" value="REENVIAR" class="btn-entrar">
                    <p class="relogio" v-if="isButtonDisabled">{{ timerDisplay }}</p>
                </form>
                
            </div>
             <TheFooter />
        </div>
        
    </section>
   
</template>

<script>

import TheFooter from "@/components/Base/TheFooter.vue";
import { api } from "@/services.js";
export default {
  name: "ReemviarEmail",
  components: {
    TheFooter
  },
  data() {
    return {
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
      isButtonDisabled: false,
      timer: 0,
      loading: false,
      form: {
        email: ""   
      }
      
    };
  },

   computed: {
    timerDisplay() {
      const minutes = String(Math.floor(this.timer / 60)).padStart(2, '0');
      const seconds = String(this.timer % 60).padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
  },
  methods: {

    submitReenvio() {
      this.loading = true;
      
       const data = {
            ...this.form,
        };

      api
        .post("/usuario/reenviar-email", data)
            .then((response) => {
            console.log(response.data);

            this.notificacao_infos = {
                variant: "success",
                mensagem: "O e-mail de ativação de conta foi reenviado!",
                count_timer: 5,
            };

            this.startTimer();

        })
        .catch((error) => {
            console.error(error);

            this.notificacao_infos = {
                        variant: "error",
                        mensagem: error.response
                        ? error.response.data.errors[0]?.message
                        : "Algo deu errado na requisição",
                        count_timer: 5,
                    };

        })
        .finally(() => {
            this.loading = false;
        });
      
    },

    startTimer() {
      this.isButtonDisabled = true;
      this.timer = 60;
      
      const countdown = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(countdown);
          this.isButtonDisabled = false;

          this.notificacao_infos = {
                variant: "",
                mensagem: "",
                count_timer: 5,
            };
          
        }
      }, 1000);
    },

  },

};
</script>

<style scoped>
    .form-login  {
        margin-bottom: 200px;
    }

    .relogio {
        text-align: center;
        margin-top: 10px;
    }

    .btn-entrar.disabled,
    .btn-entrar:disabled {
        background: #d5d5d5;
        color: #333 !important;
        pointer-events: none;
        box-shadow: none;
        opacity: .5;
    }

    @media (max-width: 990px) {
         .form-login  {
            margin-bottom: 50px;
        }
    }
</style>