<template>
    <div>
       <!-- <section class="fiquedeolho">
            <div class="container conteudo">
                <h2>FIQUE <strong>DE OLHO</strong></h2>
                <img
                  src="@/assets/img/Aerolight.png"
                  alt="Logo Aerolight"
                  class="aerolight"
                />
                <p>A linha de Hidratação Stanley vai ficar ainda mais completa com a garrafa térmica Aerolight™ Transit, 
                    uma alternativa mais leve e discreta, ideal para quem está com a garrafa sempre na bolsa ou na mochila.</p>

                 <a href="https://www.stanley1913.com.br/busca?busca=aerolight" class="saibamais" style="text-decoration: none;" target="_blank">
                    SAIBA MAIS
                </a>
            </div>
        </section>-->

        <section class="secaofiquedeolho">
            <a v-if="banners.length > 0" :href="banners[0].url" target="_blank"> 
                 <img
                  :src="banners[0].urlImg"
                  alt="Logo Aerolight"
                  class="verdesktop"
                />

                <img
                  :src="banners[0].urlImgMobile"
                  alt="Logo Aerolight"
                  class="vermobile"
                />
            </a>
           
        </section>
    </div>
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "FiqueDeOlho",
  data() {
    return {
      banners: [],
    }
 },
  methods: {
    fetchBanners() {
        this.loading = true;
        api
          .get('/banner')
          .then((resp) => {
            this.banners = resp.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
  mounted() {
    this.fetchBanners();
  }
};
</script>

<style scoped>

.secaofiquedeolho {
  position: relative;
  z-index: 999;
}

.aerolight {
    width: 435px;
    margin: 40px 0px;
}

.fiquedeolho {
    background-image: url('@/assets/img/FIQUEDEOLHO_desk.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 70px 0px;
}

.fiquedeolho h2 {
    letter-spacing: 4px;
    font-family: "klavika medium";
    color: #232527;
    font-size: 90px;
}

.fiquedeolho h2 strong {
    letter-spacing: 6.3px;
    font-family: "KNOCKOUT-HTF70";
    color: #FF681E;
}

.fiquedeolho p {
    font-family: "klavika light";
    color: #232527;
    font-size: 19px;
    width: 430px;
    margin-bottom: 40px;
}

.saibamais {
    transition: background-color 0.3s ease;
    background-color: transparent;
    color: #232527;
    border: 2px solid #232527;
    font-size: 21px;
    cursor: pointer;
    padding: 10px 60px;
    font-family: "klavika medium";
}

.saibamais:hover {
    background-color: #232527;
    color: #D9D8D6;
}

@media (min-width: 991px) {
  .vermobile {
    display: none;
  }
}

@media (max-width: 990px) {

    .verdesktop {
      display: none;
    }

    .conteudo {
        padding: 0px 20px;
    }

    .fiquedeolho {
        padding: 50px 0px 50px 0px;
        background-image: url('@/assets/img/aeromob.png');
    }
    
    .fiquedeolho h2 {
        width: 250px;
        font-size: 60px;
        line-height: 60px;
    }

    .aerolight {
        width: 200px;
        margin: 200px 0px 0px 0px;
    }

    .fiquedeolho p {
        line-height: 22px;
        font-family: "klavika medium";
        font-size: 17px;
        margin-top: 40px;
        width: 220px;
    }
}
</style>