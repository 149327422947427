<template>
  <div>
    <div class="loading_global" v-if="loading">
        <span class="loader"></span>
    </div>

    <section class="login" data-anima="top" v-if="!mensagem">
        <router-link to="/"><img
                src="@/assets/img/logo_legionarios_preto.svg"
                alt="Logo Stanley Legionários"
                class="logo"
        /></router-link>
        <h1>REDEFINA SUA <strong class="strong">SENHA</strong></h1>
        <p class="info">Insira seu endereço de email e nós enviaremos instruções para redefinir sua senha.</p>
        <div class="form-login">
          <Notificacao
              :infos="notificacao_infos"
              v-if="notificacao_infos.mensagem"
            />
            <form @submit.prevent="onSubmit">
                <label for="email">Endereço de e-mail</label>
                <input type="email" id="email" name="email" v-model="email"><br><br>
                
                <button type="submit" class="btn-entrar">CONTINUAR</button>
            </form>
            
            
        </div>
    </section>

    <section class="login esqueceusenha" data-anima="top" v-else>
        <img
                src="@/assets/img/logo_legionarios_branca.svg"
                alt="Logo Stanley Legionários"
                class="logo"
        />

        <div class="conteudo-mensagem">
          <h1 class="atencao">Você receberá um email para redefinir sua senha!</h1>
          <p class="info-email">Caso exista uma conta cadastrada, um e-mail será enviado. Clique no botão REDEFINIR SENHA para criar uma nova senha.</p>
          <router-link to="/login" class="link-login" style="text-decoration: none;">LOGIN</router-link>
        </div>
    </section>
    <TheFooter />
  </div>
    
</template>

<script>
import TheFooter from "@/components/Base/TheFooter.vue";
import { api } from "@/services.js";
export default {
  name: "EsqueciSenha",
  components: {
    TheFooter
  },
  data() {
    return {
      email: "" ,
      mensagem: false,
      loading: false,
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
    },
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      api
        .get(`/auth/recuperar-senha?email=${this.email}`)
        .then(() => {
          this.mensagem = true;
          
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.mensagem = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    if (window.localStorage.token) {
      this.$router.push({ path: "/home"});
    } 
  }
};
</script>

<style scoped>

.esqueceusenha {
    background-color: #D4D1C4;
    height: 100vh;
}

.info {
    margin: 0 auto;
    width: 300px;
    padding-bottom: 30px;
    color: #52575A;
    font-size: 16px;
    padding-top: 30px;
}


.info-email {
    text-align: center;
    width: 400px;
    margin: 0 auto;
    padding-bottom: 30px;
    color: #fff;
    font-size: 16px;
    padding-top: 30px;
}


.atencao {
    font-size: 25px;
    font-family: "klavika-web", sans-serif;
    width: 500px;
    margin: 0 auto;
    line-height: 30px;
    padding-top: 70px;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
}

.btn-entrar {
  margin-bottom: 80px !important;
  transition: background-color 0.3s ease;
  background-color: #00abc8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  padding: 10px 50px;
  margin: 0 auto; 
  display: block;
  text-align: center;
  width: 300px;
  height: 50px;
}


.link-login {
  transition: background-color 0.3s ease;
  background-color: #00abc8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  padding: 10px 50px;
  margin: 0 auto; 
  display: block;
  text-align: center;
  width: 250px;
}

.link-login:hover, .btn-entrar:hover {
  background-color: #00b6d5;
}

.esqueceusenha {
    background-image: url('@/assets/img/BG_SUCESSO_LOGIN.png');
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 991px) {
  .conteudo-mensagem .copyright {
    display: none;
  }
}

@media (max-width: 990px) {

  .copyright {
    margin: 30px 0px;
  }

  .login .logo {
      padding-top: 40px;
      width: 150px;
      height: 85px;
  }

  .copyright {
      margin-bottom: 30px;
  }
  .info, .atencao, .info-email {
    width: 100%;
  }

  .esqueceusenha {
    padding: 0px 20px;
  }

  .info {
    padding-top: 0px;
    text-align: center;
   }

   .esqueceusenha {
    display: flex;
    justify-content: center;
    background-image: url('@/assets/img/bg-senha.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #8d4920;
  }

  .conteudo-mensagem {
    padding: 0px 20px;
    position: absolute;
    bottom: 30px;
  }

  .conteudo-mensagem h1, .conteudo-mensagem p {
    color: white;
  }

  .atencao {
    font-size: 19px;
    width: 100%;
    line-height: 24px;
  }

}
</style>