import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Loading from '@/components/Base/Loading.vue'
import Notificacao from '@/components/Base/Notificacao.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueTheMask from 'vue-the-mask';
import VueCarousel from 'vue-carousel';
 
Vue.use(VueCarousel);
Vue.config.productionTip = false
Vue.component('Loading', Loading)
Vue.component('Notificacao', Notificacao)
Vue.use(BootstrapVue)
Vue.use(VueTheMask);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

new Vue({
  el: "#app-carousel",
  data: {
    slide: 0,
    sliding: null,
    dadosProdutos: [
    ]
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  },
});