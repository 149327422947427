import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Start from '@/views/Start.vue'
import Login from '@/views/Login.vue'
import Cadastro from '@/views/Cadastro.vue'
import ContaAtivada from '@/views/ContaAtivada.vue'
import EsqueciSenha from '@/views/EsqueciSenha.vue'
import NovaSenha from '@/views/NovaSenha.vue'
import EditarPerfil from '@/views/EditarPerfil.vue'
import AlterarEmail from '@/views/AlterarEmail.vue'
import AlterarSenha from '@/views/AlterarSenha.vue'
import CadastrarProduto from '@/views/CadastrarProduto.vue'
import EditarProduto from '@/views/EditarProduto.vue'
import PersonalizarPortal from '@/views/PersonalizarPortal.vue'
import CadastroRedeSocial from '@/views/CadastroRedeSocial.vue'
import Indicacoes from '@/views/Indicacoes.vue'
import Extrato from '@/views/Extrato.vue'
import Beneficios from '@/views/Beneficios.vue'
import PaginaNaoEncontrada from '@/components/Base/PaginaNaoEncontrada.vue'
import ReenviarEmail from '@/views/ReenviarEmail.vue'
import FriendsAndFamily from '@/views/FriendsAndFamily.vue'
import CaptacaoLeads from '@/views/CaptacaoLeads.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Início',
  component: Start
},
{
  path: '/login*',
  name: 'Login',
  component: Login,
},
{
  path: '/cadastro',
  name: 'Cadastro',
  component: Cadastro
},
{
  path: '/novo-registro',
  name: 'CadastroRedeSocial',
  component: CadastroRedeSocial,
},
{
  path: '/ativar-conta',
  name: 'ContaAtivada',
  component: ContaAtivada
},
{
  path: '/esqueceu-a-senha',
  name: 'EsqueciSenha',
  component: EsqueciSenha
},
{
  path: '/recuperar-senha',
  name: 'NovaSenha',
  component: NovaSenha
},
{
  path: '/ativacao',
  name: 'ReenviarEmail',
  component: ReenviarEmail
},
{
  path: '/expert-xp/',
  name: 'CaptacaoLeads',
  component: CaptacaoLeads
},
{
  path: '/editar-perfil',
  name: 'EditarPerfil',
  component: EditarPerfil,
  meta: {
    login: true,
  },
},
{
  path: '/alterar-email',
  name: 'AlterarEmail',
  component: AlterarEmail,
  meta: {
    login: true,
  },
},
{
  path: '/alterar-senha',
  name: 'AlterarSenha',
  component: AlterarSenha,
  meta: {
    login: true,
  },
},
{
  path: '/novo-produto',
  name: 'CadastrarProduto',
  component: CadastrarProduto,
  meta: {
    login: true,
  },
},
{
  path: '/editar-produto/:id',
  name: 'EditarProduto',
  component: EditarProduto,
  meta: {
    login: true,
  },
},
{
  path: '/personalizar-portal',
  name: 'PersonalizarPortal',
  component: PersonalizarPortal,
  meta: {
    login: true,
  },
},
{
  path: '/indicacoes',
  name: 'Indicacoes',
  component: Indicacoes,
  meta: {
    login: true,
  },
},
{
  path: '/extrato',
  name: 'Extrato',
  component: Extrato,
  meta: {
    login: true,
  },
},
{
  path: '/beneficios',
  name: 'Beneficios',
  component: Beneficios,
  meta: {
    login: true,
  },
},
{
  path: '/home',
  name: 'Home',
  component: Home,
  meta: {
    login: true,
  },
},
{
  path: '*',
  name: 'PaginaNaoEncontrada',
  component: PaginaNaoEncontrada
},
{
  path: '/friends-and-family',
  name: 'FriendsAndFamily',
  component: FriendsAndFamily,
  meta: {
    login: true,
  },
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
})

router.beforeEach((to, from, next) => {
  document.title = `${document.title}`;

  if (to.matched.some((route) => route.meta.login)) {

    if (!window.localStorage.token) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router