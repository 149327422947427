<template>

    <div>
        <div class="loading_global" v-if="loading">
            <span class="loader"></span>
        </div>
    
        <section class="login" data-anima="bottom" v-if="!senhaRedefinida">
            <img
                    src="@/assets/img/logo_legionarios_preto.svg"
                    alt="Logo Stanley Legionários"
                    class="logo"
            />
            <h1>CRIE SUA <strong class="strong">NOVA SENHA</strong></h1>
            <div class="form-login">
              <Notificacao
                :infos="notificacao_infos"
                v-if="notificacao_infos.mensagem"
              />
                <form @submit.prevent="submitForm">                
                    <label for="senha">Senha</label>
                    <div class="campo-senha">
                        <input :type="mostrarSenha ? 'text' : 'password'" id="senha" name="senha" v-model="form.senha" required><br><br>
                        <button @click.prevent="toggleMostrarSenha" class="btn-view">
                                <img v-if="mostrarSenha"
                                    src="@/assets/img/noview.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                                <img v-else
                                    src="@/assets/img/view.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                            </button>
                    </div>

                    <label for="senha">Confirmar senha</label>
                    <div class="campo-senha">
                        <input :type="mostrarConfirmarSenha ? 'text' : 'password'" id="confirmacaosenha" name="senha" v-model="form.confirmacaoSenha" required  onpaste="return false" ondrop="return false"><br><br>
                        <button @click.prevent="toggleMostrarConfirmarSenha" class="btn-view">
                                <img v-if="mostrarConfirmarSenha"
                                    src="@/assets/img/noview.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                                <img v-else
                                    src="@/assets/img/view.png"
                                    alt="Icone de visualização"
                                    class="view"
                                />
                            </button>
                    </div>

                    <p class="txtregra">A senha deve conter pelo menos:</p>
                    <p :class="verificarSenhaPreenchida().letra">- 1 Letra maiúscula</p>
                    <p :class="verificarSenhaPreenchida().numero">- 1 Número</p>
                    <p :class="verificarSenhaPreenchida().caracterEspecial">
                        - 1 Caractere especial (ex:#?!)
                    </p>

                    <button type="submit" class="btn-entrar" 
                    :class="{'btn-disabled': !verificarSenhaPreenchidaCorretamente()}"
                    :disabled="!verificarSenhaPreenchidaCorretamente()">REDEFINIR</button>
                </form>

                
                
            </div>
        </section>

        <section class="senharedefinida" v-else>
            <img
                    src="@/assets/img/logo_legionarios_branca.svg"
                    alt="Logo Stanley Legionários"
                    class="logo"
            />
            <div class="conteudo-sucesso" data-anima="top">
                <h1>SENHA ATUALIZADA COM <strong>SUCESSO!</strong></h1>

                <router-link to="/login" data-anima="bottom">
                    <button type="button" class="btn-login">LOGIN</button>
                </router-link>

            </div>

    </section>
  <TheFooter />
    </div>
</template>

<script>
import TheFooter from "@/components/Base/TheFooter.vue";
import { api } from "@/services.js";
export default {
  name: "NovaSenha",
  components: {
    TheFooter
  },
  data() {
    return {
        notificacao_infos: {
          variant: "info",
          mensagem: "",
          count_timer: 0,
      },
        mostrarConfirmarSenha: false,
        mostrarSenha: false,
        form: { 
            senha: "",
            confirmacaoSenha: ""
        },
        hash: "",
        code: "",
        loading: false,
        senhaRedefinida: false
      
    };
  },
  methods: {
    toggleMostrarSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },
    toggleMostrarConfirmarSenha() {
      this.mostrarConfirmarSenha = !this.mostrarConfirmarSenha;
    },
    verificarSenhasIguais() {
        return this.form.senha === this.form.confirmacaoSenha;
    },
    verificarLetra() {
       const letraRegex = /[a-zA-Z]/;
        const possuiLetra = letraRegex.test(this.form.senha);
        const possuiLetraMaiuscula = /[A-Z]/.test(this.form.senha);

        if (possuiLetra && possuiLetraMaiuscula) {
            return "regra-valida";
        } else {
            return "regra-invalida";
        }
    },

    verificarNumero() {
      const numeroRegex = /[0-9]/;
      const possuiNumero = numeroRegex.test(this.form.senha);
      return possuiNumero ? "regra-valida" : "regra-invalida";
    },

    verificarCaracterEspecial() {
      const caracterEspecialRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const possuiCaracterEspecial = caracterEspecialRegex.test(this.form.senha);
      return possuiCaracterEspecial ? "regra-valida" : "regra-invalida";
    },

    verificarSenhaPreenchida() {
      const classes = {
        letra: this.verificarLetra(),
        numero: this.verificarNumero(),
        caracterEspecial: this.verificarCaracterEspecial(),
      };

      return classes;
    },

    verificarSenhaPreenchidaCorretamente() {
      const letraRegex = /[a-zA-Z]/;
      const numeroRegex = /[0-9]/;
      const caracterEspecialRegex = /[!@#$%^&*(),.?":{}|<>]/;
      const LetraMaiusculaRegex = /[A-Z]/;

      const possuiLetra = letraRegex.test(this.form.senha);
      const possuiNumero = numeroRegex.test(this.form.senha);
      const possuiCaracterEspecial = caracterEspecialRegex.test(this.form.senha);
      const possuiLetraMaiuscula = LetraMaiusculaRegex.test(this.form.senha);

      const senhasIguais = this.form.senha === this.form.confirmacaoSenha;

      return possuiLetra && possuiNumero && possuiCaracterEspecial && possuiLetraMaiuscula && senhasIguais;
    },

    submitForm() {
        
      const data = {
        code: this.code,
        hash: this.hash,
        ...this.form,
      }
      this.loading = true;
        api
        .post(`/auth/recuperar-senha-confirmacao`, data)
        .then((resp) => {
          console.log(resp);
          this.senhaRedefinida = true;
        })
        .catch((error) => {
          console.log(error);
          this.notificacao_infos = {
              variant: "error",
              mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
              count_timer: 5,
          };
        })
        .finally(() => {
          this.loading = false;
        });
     
    },
  },
  mounted() {
    this.code = this.$route.query.q;
    this.hash = this.$route.query.h;
    if(!this.code && !this.hash) {
        this.$router.push("/login")
    } 
  },
};
</script>

<style scoped>

.txtcadastro {
    color: #52575A;
    font-size: 16px;
    text-align: center;
    margin: 20px 0px;
}

.copyright {
  margin-top: 50px;
}

.view {
    margin-top: -21px;
}

.btn-entrar:hover {
  background-color: #00b6d5;
}

.btn-entrar {
  transition: background-color 0.3s ease;
  background-color: #00abc8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  padding: 10px 50px;
  margin: 0px auto; 
  display: block;
  text-align: center;
  width: 300px;
  height: 50px;
  margin-top: 30px !important;
  margin-bottom: 60px !important;
}

.anterior {
    background-color: transparent;
    border: none;
}

.seta {
    width: 14px;
    margin-right: 15px;
    cursor: pointer;
}

.voltar {
    display: flex;
    margin: 15px 0px 30px 0px;
}

.voltar span {
    color: #52575A;
    font-weight: 200; 
}

.voltar p {
    line-height: 17px;
    color: #232527;
    font-size: 14px;
    font-family: "klavika-web", sans-serif;
    font-weight: 700;   
}

.linha-cinza {
    height: 1px;
    width: 300px;
    background-color: #a2a2a2;
}

.linha-etapa {
    height: 2px;
}

.etapa-senha {
    width: 100px;
    background-color: #00ABC8;
}

.etapa-cpf {
    width: 200px;
    background-color: #00ABC8;
}

.etapa-final {
    width: 300px;
    background-color: #00ABC8;
}

.espaco {
    margin-top: 60px;
}

input, select {
    margin-bottom: 20px;
    padding: 5px;
}

.data-nasc {
    display: flex;
    gap: 10px;
}

.data-nasc input, .data-nasc select {
    color: #52575A;
    font-size: 16px;
    font-family: "klavika-web-display", sans-serif;
    font-weight: 200;
}

#dia {
    width: 60px;
}

#ano {
    width: 80px;
}

.generos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
 }

.grupo1, .grupo2 {
    height: 40px;
    display: flex;
    gap: 12px !important;
}

.generos label {
    color: #52575A;
    font-size: 14px;
    font-family: "klavika-web", sans-serif;
    font-weight: 400;
    margin-top: 16px;
}

.generos input {
    width: 20px !important;
    margin-bottom: 0px !important;
}

.checkbox-form label {
    color: #52575A;
    font-size: 14px;
    font-family: "klavika-web", sans-serif;
    font-weight: 400;
}

.checkbox-form input {
    margin-right: 8px;
    margin-bottom: 0px;
    position: relative;
    top: -12px;
}

.checkbox-form {
    display: flex;
    margin-bottom: 0px;
}

#nao {
    margin-left: 14px;
}

.link-termo {
    text-decoration: underline;
    color: #52575A;
}

.margin-btn {
    margin-top: 30px;
}

input[type='radio']:checked:after {
    width: 18px;
    height: 18px;
    border-radius: 15px;
    top: 17px;
    left: -1px;
    position: relative;
    background-color: #00ABC8;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='checkbox']:checked:after {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    top: 17px;
    left: -1px;
    position: relative;
    background-color: #00ABC8;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

.regra-valida {
  color: #00abc8;
}

.regra-invalida {
  color: #e20000;
}

.btn-disabled {
  background-color: #03a5c14a !important;
  cursor: auto;
}

.senharedefinida {
    height: 100vh;
    background-color: #52575A;
}

.senharedefinida h1 {
  color: #FFFFFF;
  font-size: 32px;
  font-family: "klavika-web-display", sans-serif;
  font-weight: 200;
  line-height: 35px;
  margin-bottom: 20px;
}

.senharedefinida strong {
  color: #FFFFFF;
  font-family: "klavika-web", sans-serif;
  font-weight: 700;
}

.senharedefinida p {
  color: #FFFFFF;
  font-size: 16px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}

.conteudo-sucesso {
    width: 300px;
    margin: 0 auto;
    padding-top: 120px;
}

.btn-login {
  transition: background-color 0.3s ease;
  background-color: #00ABC8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  width: 300px;
  height: 48px;
  margin: 30px auto;
  display: block;
}

.btn-login:hover {
  background-color: #00b6d5;
}

.copyright {
  margin-top: 70px !important;
}

@media (max-width: 990px) {

 .senharedefinida {
    background-image: url('@/assets/img/bg-senha.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .logo {
    padding-top: 40px;
    margin: 0px 30px;
  }

  .conteudo-sucesso {
    padding: 0px 30px;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }

  .btn-login {
    margin: 30px 0px;
  }

  .copyright {
    font-size: 13px !important;
  }
  

  .login .logo {
      padding-top: 40px;
  }

  .login h1 {
      margin: 20px 0px;
  }
  
  .txtcadastro {
      margin: 30px 0px;
  }

  .copyright {
      margin-bottom: 30px;
  }
}

</style>