<template>
        <div class="mob menu-banner">
                <div>
                  <router-link to="/home">
                     <img
                        v-if="isWhite"
                        src="@/assets/img/logo_legionarios_branca.svg"
                        alt="Logo Stanley Legionários - Branco"
                        class="logo-mobile"
                      />
                      <img
                        v-else
                        src="@/assets/img/logo_legionarios_preto.svg"
                        alt="Logo Stanley Legionários - Preto"
                        class="logo-mobile"
                      />
                  </router-link>
                </div>
                <div class="side-mob">
                  <b-button v-b-toggle.sidebar-right class="btn-mobile">
                    <img
                      src="@/assets/img/menu.svg"
                      alt="Icone menu mobile"
                  />
                  </b-button>
                  <b-sidebar id="sidebar-right" :title="primeiroNome" class="sidebar-mobile" right>
                    <div class="px-3 py-2">
                      <b-navbar-nav class="menu-mobile">
                          <b-nav-item :class="{ 'active': currentRoute === '/home' }" href="./home">INÍCIO</b-nav-item>
                          <b-nav-item :class="{ 'active': currentRoute === '/novo-produto' }" href="./novo-produto">NOVO PRODUTO</b-nav-item>
                          <b-nav-item :class="{ 'active': currentRoute === '/editar-perfil' }" href="./editar-perfil">EDITAR PERFIL</b-nav-item>
                          <b-nav-item :class="{ 'active': currentRoute === '/indicacoes' }" href="./indicacoes">INDIQUE E GANHE</b-nav-item>  
                          <b-nav-item :class="{ 'active': currentRoute === '/extrato' }" href="./extrato">MEU EXTRATO</b-nav-item>  
                          <b-nav-item :class="{ 'active': currentRoute === '/friends-and-family' }" v-if="usuarioLogado.colaborador" href="./friends-and-family">FRIENDS & FAMILY</b-nav-item>  
                          <b-nav-item :class="{ 'active': currentRoute === '/sair' }" href="#" @click.prevent="deslogarUsuario">SAIR</b-nav-item>
                        </b-navbar-nav>
                    </div>
                  </b-sidebar>
                </div>
                
        </div>  
</template>

<script>
import { api } from "@/services.js";
export default {
  name: "MenuMobile",
  data() {
    return {
      isWhite: false,
      currentRoute: '',
      usuarioLogado: []
    }
 },
 created() {
    this.currentRoute = this.$route.path; 
  },
   methods: {
      deslogarUsuario() {
        this.$store.dispatch('deslogarUsuario');
        this.$router.push('/');
      },

       buscarUsuarios() {
        this.loading = true;
        api
          .get('/usuario/logged')
          .then((resp) => {
            this.usuarioLogado = resp.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
  },
  computed: {
    primeiroNome() {
      if (this.usuarioLogado.nome) {
        const nomeCompleto = this.usuarioLogado.nome;
        const primeiroNome = nomeCompleto.split(' ')[0];
        return primeiroNome;
      } else {
        return '';
      }
   }
  },
   mounted() {
    this.buscarUsuarios();
    if (this.$route.path === "/home" || this.$route.path === "/extrato" || this.$route.path === "/beneficios" || this.$route.path === "/indicacoes" || this.$route.path === "/friends-and-family") {
      this.isWhite = true;
    } 
  }
};
</script>

<style scoped>

  .btn-login {
    transition: background-color 0.3s ease;
    background-color: #00ABC8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    width: 300px;
    height: 48px;
    margin: 30px auto;
    display: block;
  }

  .btn-login:hover {
    background-color: #00b6d5;
  }

  .btn-mobile {
    padding: 5px !important;

    right: 15px;
    border: none;
    background-color: transparent;
  }

  .btn-mobile:hover,  
  .btn-mobile:focus,  
  .btn-mobile:active, .side-mob .btn:active {
    border: none !important;
    background-color: transparent !important;
  }

  .logo-mobile {
    width: 200px;
    top: 35px;
    left: 20px;
  }

  .menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
  }

  .nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
  }

  .nav-item a:hover {
    color: #D4D1C4;
  }

  .active a:after {
    content: '';
    display: inline-block;
    width: 8px; 
    height: 8px; 
    border-radius: 50%;
    background-color: #FF681E; 
    margin-left: 12px;
    position: relative;
    top: -3px;
  }

  .active a {
    color: #D4D1C4 !important;
  }

  .mob {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
  }


@media (max-width: 990px) {

  .logo-mobile {
    width: 150px;
  }


  .btn-avancar {
    width: 100%;
  }
   
}
</style>