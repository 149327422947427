<template>
  <section class="container">
    <div class="pg-nao-encontrada">
      <h1>Página não encontrada 👋</h1>
      <router-link to="/" title="Retornar para home" class="voltar">Voltar para home</router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "PaginaNaoEncontrada",
};
</script>

<style scoped>
.container {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pg-nao-encontrada {
  background: #f7f7f7;
  padding: 40px 80px;
  border-radius: 5px;
  text-align: center;
}
h1 {
  margin-bottom: 40px;
  font-family: "klavika-web", sans-serif;
  font-weight: 400;
}
.voltar {
  transition: background-color 0.3s ease;
  background-color: #00abc8;
  color: white;
  font-size: 16px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  cursor: pointer;
  padding: 10px 20px;
}

.voltar:hover {
  background-color: #00b6d5;
}
</style>