<template>
    <div>
        <div class="loading_global" v-if="loading">
         <span class="loader"></span>
        </div>
        <div class="lp-captacao" v-else>
            <div>
                <img src="@/assets/img/leads/bg-evento.png" alt="Imagem de header do evento para captação de leads" class="img-desk" />
                <img src="@/assets/img/leads/bg-evento-mob.png" alt="Imagem de header do evento para captação de leads" class="img-mob" />
            </div>
            <div class="conteudo-leads" v-if="!finalizado">
                <h1>Ganhe um cupom de 40% OFF* em todo o site Stanley</h1>
                <p>Preencha seus dados e receba um cupom exclusivo da Stanley para os Associados XP. </p>
                    <Notificacao
                    :infos="notificacao_infos"
                    v-if="notificacao_infos.mensagem"
                    />
                    <form @submit.prevent="submitForm">
                        <label for="nome" class="label">Nome</label>
                        <input type="text" id="nome" name="nome" v-model="form.nome" required />

                        <label for="email">E-mail</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            v-model="form.email"
                            required
                        />

                        <label for="celular" class="label">Celular</label>
                        <the-mask
                            type="text"
                            id="celular"
                            name="celular"
                            v-model="form.celular"
                            :mask="'(##) #####-####'"
                            inputmode="numeric"
                            required
                            :masked="false"
                        />

                        <label for="datanasc" class="label">Data de nascimento</label>
                        <div class="data-nasc">
                            <input
                            type="date"
                            id="dtNascimento"
                            name="dtNascimento"
                            placeholder="00/00/0000"
                            v-model="form.dtNascimento"
                            required
                            />
                        </div>

                        <label for="cep">CEP</label>
                        <div>
                            <the-mask
                                type="text"
                                id="cep"
                                name="cep"
                                v-model="form.cep"
                                :mask="'#####-###'"
                                inputmode="numeric"
                                required
                                :masked="false"/>
                        </div>

                        <div class="checkbox-form d-flex">
                            <input
                                type="checkbox"
                                id="optinEmail"
                                name="optinEmail"
                                v-model="form.optinEmail"
                                
                            />
                            <label for="termo">Quero receber novidades e promoções exclusivas da Stanley e do Portal Legionários por e-mail.</label><br />
                        </div>

                        <div class="checkbox-form d-flex">
                            <input
                                type="checkbox"
                                id="optinCelular"
                                name="optinCelular"
                                v-model="form.optinCelular"
                                
                            />
                            <label for="termo">Quero receber novidades e promoções exclusivas da Stanley e do Portal Legionário por SMS e Whatsapp.</label><br />
                        </div>
                        <button
                            class="btn-cadastrar">
                            CADASTRAR
                        </button>

                        <p class="texto-legal">
                            Ao fornecer suas informações você autoriza a STANLEY a realizar o tratamento de seus dados pessoais. Assim, 
                            a partir desta ação, você consente que a STANLEY envie e-mail, SMS ou WhatsApp com promoções, newsletter e 
                            informações sobre parcerias. Você declara também ter conhecimento e concordar com a nossa Política de Privacidade. 
                            <a href="https://atendimentostanley.zendesk.com/hc/pt-br/sections/360010713671" target="_blank">Saiba mais.</a><br><br>
                            *Cupom válido para compras realizadas no site www.stanley1913.com.br por tempo limitado. 
                            Disponível apenas para primeira compra no site. Limite de 1 uso por CPF. Sujeito à disponibilidade de estoque.
                        </p>
                    </form>
            </div>

            <div v-else class="conteudo-sucesso">
                <div class="d-flex voltarCadastro">
                    <img src="@/assets/img/leads/seta-preta.png" alt="Imagem de seta"  @click="voltar" class="seta"/>
                    <button
                        @click="voltar"
                        class="btn-voltar">
                        VOLTAR
                    </button>
                </div>
                <h2>CUPOM RESGATADO!</h2>
                <div class="codigo">
                    <input class="codigo-cupom" value="XP_Stanley_1913" />
                    <img          
                        src="@/assets/img/copiar.svg"
                        alt="Icone de copiar"
                        class="icone-copiar"
                        @click="copiarTexto($event)"
                    />
                </div>
                <p>A Stanley e a XP agradecem sua participação neste evento especial. Para utilizar o seu cupom de 40% de desconto, 
                    basta adicionar o código no carrinho de compras ao realizar seu pedido no site.</p>

                <button
                    @click="site"
                    class="btn-cadastrar">
                    IR PARA O SITE
                </button>

            </div>

            <div class="rodape-leads">
                <a href="https://www.stanley1913.com.br/" target="_blank"><img src="@/assets/img/leads/logo-branca.png" alt="Logo da stanley" class="img-rodape"/></a>
                <p class="copyright-rodape">© {{ ano_atual }} Stanley - CNPJ: 30.055.933/0006-51 Todos os direitos reservados</p>
            </div>
        </div>
    </div>
</template>

<script>
import { api } from "@/services.js";
import { TheMask } from "vue-the-mask";
export default {
 name: "CaptacaoLeads",
  components: {
    TheMask,
  },
  data() {
    return {
        finalizado: false,
        form: {
            nome: "",
            email: "",
            celular: "",
            dtNascimento: "",
            cep: "",
            optinEmail: "",
            optinCelular: ""
        },
        loading: false,
        notificacao_infos: {
            variant: "info",
            mensagem: "",
            count_timer: 0,
        },
    }
  },
   computed: {
    ano_atual() {
      return new Date().getFullYear();
    },
   },
  methods: {
    site(){
        window.open("https://www.stanley1913.com.br/", "_blank")
    },

    copiarTexto(event) {
      const inputElement = event.target.previousElementSibling;
      inputElement.select();
      document.execCommand('copy');
    },  

    voltar() {
        this.finalizado = false;
        this.$router.go();
    },

     submitForm() {
        
        this.loading = true;
            
            const data = {
                ...this.form,
                dtNascimento: new Date(this.form.dtNascimento).toISOString(),
            };
            
            console.log("data", data);

            api
                .post("/Leads/Create", data)
                    .then((response) => {
                    console.log(response.data);
   
                    this.form = {
                        nome: "",
                        email: "",
                        celular: "",
                        dtNascimento: "",
                        cep: "",
                        optinEmail: "",
                        optinCelular: "",
                    };

                })
                .catch((error) => {
                    console.error(error);

                    this.notificacao_infos = {
                        variant: "error",
                        mensagem: error.response
                        ? error.response.data.errors[0]?.message
                        : "Algo deu errado na requisição",
                        count_timer: 5,
                    };

                     window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });

                })
                 .finally(() => {
                    this.loading = false;
                    this.finalizado = true;
                });

    },

  }

}
</script>

<style scoped>
.icone-copiar {
    cursor: pointer;
    filter: invert(1);
}

.conteudo-leads {
    width: 300px;
    margin: auto;
    padding-top: 30px;
}

.conteudo-leads h1 {
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    color: #000000;
    font-size: 18px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
}

.conteudo-leads p {
    color: #000000;
    font-size: 14px;
    font-family: "klavika light";
    letter-spacing: 1.4px;
}

.conteudo-leads input {
    border-radius: 7px;
    height: 48px;
    font-family: "klavika medium" !important;
    font-weight: 400 !important;
    padding: 15px 15px !important;
}

.conteudo-leads label {
    margin-bottom: 0px;
    letter-spacing: 3.2px;
    font-family: "klavika bold";
    text-transform: uppercase;
    margin-top: 15px;
}

.checkbox-form label {
    letter-spacing: 0px !important;
    font-family: "klavika Light" !important;
    text-transform: initial !important;
}

.checkbox-form {
    gap: 10px;
    align-items: center;
}

.checkbox-form label {
    font-size: 12px;
    margin-bottom: 0px;
}

.texto-legal {
    font-size: 12px !important;
    font-family: "klavika light" !important;
    line-height: 15px !important;
    letter-spacing: 0px !important;
    margin-bottom: 50px;
}

.texto-legal a {
    color: #000 !important;
}

.btn-cadastrar {
  transition: background-color 0.3s ease;
  background-color: #FF681E;
  color: white;
  font-size: 17px;
  border: none;
  box-shadow: 2px 3px 9px #00000033;
  height: 48px;
  font-family: "klavika medium";
  width: 100%;
  margin: 20px 0px;
}

.btn-cadastrar:hover {
  background-color: #f35b11;
}

.btn-voltar {
    background-color: transparent !important;
    border: none !important;
}

.rodape-leads {
    background-color: #000000;
    padding: 40px 0px 20px 0px;
}

.img-rodape {
    width: 210px;
    margin: auto;
}

.copyright-rodape {
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
    font-family: "klavika light";
    letter-spacing: 1.2px;
    margin-top: 30px;
}

.conteudo-sucesso {
    width: 350px;
    margin: auto;
}

.conteudo-sucesso h2 {
    color: #000000;
    font-size: 45px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    margin-top: 20px;
}

.conteudo-sucesso p {
    color: #000000;
    font-size: 14x;
    font-family: "klavika light";
}

.conteudo-sucesso .btn-cadastrar {
    margin-bottom: 70px;
}

.seta {
    width: 20px;
    height: 20px;
}

.voltarCadastro {
    margin-top: 50px;
}

.codigo {
    padding: 5px 10px;
    border: 2px dashed black;
    margin-bottom: 20px;
    display: flex;
}

.codigo input {
    font-size: 25px;
    text-transform: uppercase;
    border: none;
    padding: 0px;
}

@media (min-width: 1600px) {
    .conteudo-sucesso {
        min-height: 490px;
    }
}

@media (max-width: 767px) {
    .conteudo-leads {
        padding: 30px !important;
        width: 100%;
   }
   .conteudo-sucesso {
        width: 100%;
       padding: 30px !important;
    }
}

@media (max-width: 990px) {
  .img-desk {
    display: none;
  }

  .copyright-rodape  {
    padding: 0px 15px;
  }

  .texto-legal {
    margin-bottom: 30px;
  }

  .voltarCadastro {
    margin-top: 0px;
  }

  .conteudo-sucesso .btn-cadastrar {
    margin-bottom: 20px;
  }

  .conteudo-sucesso {
    min-height: 500px;
  }
}

@media (min-width: 991px) {
  .img-mob {
    display: none;
   }
}
    
</style>
