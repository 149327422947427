<template>
    <div>
        <section class="home">

            <div class="video-container">
                <div class="menu-desk">
                    <TheMenu />
                </div>

                <img class="video videodesk desk"
                    src="https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/FF_DESK.jpg" />
                <img class="video videodesk mobile"
                    src="https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/FF_MOB.jpg" />

                <div class="container">
                    <div class="informacoes">
                        <h1>Friends</h1>
                        <h1 style="color:#00ABC8">& Family</h1>
                    </div>

                    <div class="container">
                        <div class="img-final desk">
                            <img src="@/assets/img/apoio-banner.png" alt="Logo Stanley" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div>
            <section class="container">
                <div class="loading_global" v-if="loading">
                    <span class="loader"></span>
                </div>

                <div v-else>
                    <div class="conteudo-beneficios">
                        <h5>
                            <b v-if="usuarioLogado" class="primeiro-nome">
                                {{ this.primeiroNome }},
                            </b>
                        </h5>
                        <p class="mensagem-bearforce">Você que faz parte da nossa BearForce tem agora um novo motivo
                            para trazer mais pessoas
                            para a Legião Stanley. <br />Um não, na verdade são <b>5 cupons exclusivos</b> que dão
                            direito a
                            <b>20% OFF*</b>
                            para compras no site.<br /> É só compartilhar os seus códigos abaixo com a família e os
                            amigos.
                        </p>
                        <div class="cupons d-flex" :class="{ 'estiloMobile': cupons.length == 1 }">

                            <div v-for="(cupom, index) in cupons" :key="index">
                                <div class="cupom">
                                    <img src="https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/20OFF.jpg"
                                        alt="Imagem do cupom" />
                                    <div :class="'nao-resgatado'">

                                        <p class="txt-cupom">20% OFF PARA COMPARTILHAR</p>
                                        <div class="box-indicacoes">
                                            <div class="d-flex qtd-indicacoes">
                                                <img src="@/assets/img/icone-branco.png" alt="Imagem de favicon"
                                                    class="icone-cupom" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div>
                                            <div class="btn-cupom">
                                                <button type="button" @click="openModal(cupom)" id="resgatarCupom">USAR
                                                    CUPOM</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <b-modal id="modal-resgatar" hide-footer>
                                <img style="padding: 1rem;"
                                    src="https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/20OFF.jpg"
                                    alt="Imagem do cupom" />

                                <div class="conteudo-modal">
                                    <!-- <p class="nomemodal titulo-beneficio">20% OFF PARA COMPARTILHAR</p> -->
                                    <div class="txt-cupons">
                                        <div class="btn-codigo">
                                            <hr style="margin:0px !important" />
                                            <div class="d-flex box-cupom-resgatado">
                                                <input class="codigo-cupom" v-model="modalBeneficio.cupom" />
                                                <img src="@/assets/img/copiar.svg" alt="Icone de copiar"
                                                    class="icone-copiar" @click="copiarTexto($event)" />
                                            </div>
                                            <hr style="margin:0px !important" />
                                        </div>
                                    </div>
                                    <div class="validade">*Cupom válido para compras realizadas no site
                                        www.stanley1913.com.br até as 23h59min do dia
                                        {{ formatarData(modalBeneficio.dataValidade) }}. Cada cupom pode ser
                                        utilizado uma única vez. Válidos para todos os produtos, exceto Copo Quencher em
                                        todas suas variações de cores e tamanhos. Sujeito à disponibilidade de estoque.
                                    </div>
                                    <div class="compartilhe">
                                        <div class="box-redes">
                                            <p>Compartilhar:</p>

                                            <div class="redes-sociais d-flex justify-content-between">
                                                <div class="d-flex icones">
                                                    <a :href="'mailto:?subject=Portal Legionários | 20% OFF para sua próxima compra&body=' + encodeURIComponent('Olá!') + '%0A%0A' + encodeURIComponent('Tenho um cupom de 20% de desconto* exclusivo para você usar no site da Stanley (www.stanley1913.com.br). Use o código '+ modalBeneficio.cupom + ' no seu carrinho de compras para usufruir desse benefício.') + '%0A%0A' + encodeURIComponent('Aproveite e depois me conta o que você comprou :)') + '%0A%0A' + encodeURIComponent('Esse código tem validade até o último dia do mês e não é válido para os Copos Quencher em suas variações de cores e tamanho.')"
                                                        target="_blank">
                                                        <img src="@/assets/img/email-black.svg" alt="Ícone email" />
                                                    </a>

                                                    <div style="width:20px"></div>

                                                    <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent('Olá!') + '%0A%0A' + encodeURIComponent('Tenho um cupom de 20% de desconto* exclusivo para você usar no site da Stanley (www.stanley1913.com.br). Use o código '+ modalBeneficio.cupom + ' no seu carrinho de compras para usufruir desse benefício.') + '%0A%0A' + encodeURIComponent('Aproveite e depois me conta o que você comprou :)') + '%0A%0A' + encodeURIComponent('Esse código tem validade até o último dia do mês e não é válido para os Copos Quencher em suas variações de cores e tamanho.')"
                                                        target="_blank">
                                                        <img src="@/assets/img/wpp-black.svg" alt="Ícone whatsapp" />
                                                    </a>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </b-modal>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        <br />
        <FiqueDeOlho />
        <br />
        <!-- <span class="txt-validade-footer" v-if="cupons">*Cupons válidos para compras realizadas no site
            www.stanley1913.com.br até as 23h59min do
            dia {{ cupons[0].dataValidade }}.
            Cada cupom pode ser utilizado uma única vez. Válidos para todos os produtos, exceto Copo Quencher em todas
            suas variações de cores e tamanhos. Sujeito à disponibilidade de estoque.</span> -->
        <br />
        <TheFooter />
    </div>
</template>

<script>
import { api } from "@/services.js";
import { format } from 'date-fns';
import { EventBus } from '@/EventBus.js';
import TheFooter from "@/components/Base/TheFooter.vue";
// import MenuMobile from "@/components/Base/MenuMobile.vue";
import FiqueDeOlho from "@/components/FiqueDeOlho.vue";
import TheMenu from "@/components/Base/TheMenu.vue";

export default {
    name: "FriendsAndFamily",
    components: {
        // MenuMobile,
        TheFooter,
        FiqueDeOlho,
        TheMenu
    },
    data() {
        return {
            loading: false,
            usuarioLogado: [],
            cupons: [],
            modalBeneficio: {},
            notificacao_infos: {
                variant: "info",
                mensagem: "",
                count_timer: 0,

            },
        };
    },
    created() {
        EventBus.$on('cupomGerado', this.mostrarCupom);
    },
    methods: {
        voltar() {
            this.$router.push("/home")
        },

        explicacao() {
            this.$bvModal.show('modal-saldo');
        },

        openModal(cupom) {
            this.modalBeneficio = cupom;
            this.$bvModal.show('modal-resgatar');
        },

        buscarUsuarios() {
            this.loading = true;
            api
                .get('/usuario/logged')
                .then((resp) => {
                    this.usuarioLogado = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        copiarTexto(event) {
            const inputElement = event.target.previousElementSibling;
            inputElement.select();
            document.execCommand('copy');
        },

        buscarFriends() {
            this.loading = true;
            api
                .get('/friends-family')
                .then((resp) => {
                    this.cupons = resp.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.$router.push('/home');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        formatarData(dataFim) {
            if (dataFim) {
                return format(new Date(dataFim), 'dd/MM/yyyy');
            }
            return null;
        },
    },
    beforeDestroy() {
        EventBus.$off('cupomGerado', this.mostrarCupom);
    },
    computed: {
        primeiroNome() {
            if (this.usuarioLogado.nome) {
                const nomeCompleto = this.usuarioLogado.nome;
                const primeiroNome = nomeCompleto.split(' ')[0];
                return primeiroNome;
            } else {
                return '';
            }
        },

    },
    mounted() {
        this.buscarUsuarios();
        this.buscarFriends();
    },

};
</script>

<style scoped>
.compartilhe {
    display:flex;
    align-items: center;
    justify-content: center;
}

.box-redes p {
    letter-spacing: 1.8px;
    font-family: "klavika light";
    color: black;
    font-size: 19px;
}

.redes-sociais {
    gap: 15px;
    align-items: end;
}

.mensagem-bearforce {
    text-align: left;
    font: normal normal normal 23px/28px;
    letter-spacing: 0.46px;
    color: #000000;
}

.primeiro-nome {
    text-align: left;
    font: normal normal bold 27px/28px;
    letter-spacing: 0.54px;
    color: #00ABC8;
}

.txt-validade-footer {
    text-align: center;
    font: normal normal normal 13px/16px Klavika Basic;
    letter-spacing: 0.26px;
    color: #52575A;
    opacity: 1;
}

.txt-colaborador {
    text-align: center !important;
}

.btn-colaborador {
    margin: auto !important;
}

.versaldo {
    display: grid;
    justify-content: end;
}

.btn-saldo {
    font-family: "klavika medium";
    background-color: #FF681E;
    color: white;
    margin-top: 20px;
    padding: 10px 50px;
    text-decoration: none;
    font-size: 18px;
    border: none;
    text-align: center;
}

.btn-saldo:hover {
    background-color: #ec5d16;
}

#beneficios {
    position: relative;
}

.bloqueio {
    width: 100%;
    position: absolute;
}

.box-travas {
    justify-content: start;
    gap: 50px;
}

.qtd-indicacoes {
    gap: 5px;
    align-items: center;
    justify-content: end;
}

.icone-indicacao {
    width: 50px;
}

.qtd-indicacoes h3 {
    color: white;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    font-size: 50px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.interrogacao {
    margin-top: -45px;
    cursor: pointer;
    width: 30px;
}

.txt-indicacaoes {
    text-align: right;
    margin-bottom: 0px !important;
    color: white;
    margin-top: -10px;
    font-size: 25px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
}

.btn-trava {
    transition: background-color 0.3s ease;
    background-color: #00ABC8;
    color: white;
    font-size: 18px;
    border: none;
    text-align: center;
    display: block;
    width: 300px;
    text-decoration: none;
    padding: 12px 0px;
}

.btn-bloqueio {
    transition: background-color 0.3s ease;
    background-color: #00ABC8;
    color: white;
    font-size: 18px;
    border: none;
    text-align: center;
    display: block;
    width: 300px;
    text-decoration: none;
    padding: 12px 0px;
}

.btn-trava:hover,
.btn-bloqueio:hover {
    background-color: #00b6d5;
}

.estiloPersonalizacao .nomemodal {
    text-align: center !important;
    border-bottom: 1px solid black;
}

.posicao-beneficio {
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    color: #232527;
    font-size: 20px;
    text-align: center;
    top: 30px !important;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    left: 0px;
}

.selo-numero {
    position: relative;
}

.selo-copo {
    width: 150px;
    margin: auto;
    padding-bottom: 20px;
}

.txt-parabens h2 {
    font-family: "KNOCKOUT-HTF70";
    color: #232527;
    font-size: 35px;
    text-align: center;
}

.txt-parabens h3 {
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    color: #232527;
    font-size: 23px;
    text-align: center;
}

.usarCupom {
    margin-top: 0px !important;
    margin-bottom: 20px;
    background-color: #232527 !important;
    font-size: 25px !important;
}

.bgbeneficio {
    /*background-image: url('@/assets/img/bgbeneficios.png');
    background-size: cover;
    background-repeat: no-repeat;*/
    padding-bottom: 40px;
    background-color: #565959;
}

.notificacao.error {
    width: 500px;
    margin: 0 auto;
    display: table;
    margin-bottom: 30px;
    background: rgb(255 212 214);
}

.icone-copiar {
    cursor: pointer;
    filter: invert(1);
}

.codigo-cupom {
    padding: 0px;
    border: none;
    font-family: "klavika medium";
    color: #000000;
    font-size: 25px;
}

.usoCupom {
    font-family: "klavika medium";
    margin-top: 10px;
    text-align: center;
    letter-spacing: 0.5px;
    color: white;
    font-size: 15px;
}

.dataCupom {
    text-align: center;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    margin-bottom: 0px;
}

.dataCupom span {
    font-family: "klavika medium";
    color: #87b21b;
    font-size: 15px;
    letter-spacing: 0.5px;
}

.dataCupom p {
    padding: 5px 0px;
    margin-bottom: 0px;
}

.error p {
    color: #bf0000;
    font-size: 13px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 0px;
    line-height: 14px;
    margin-top: 10px;
    padding: 10px;
    background: rgb(255 212 214);
}

.box-cupom-resgatado {
    padding: 0px 5px;
    justify-content: center;
}

.btn-cupom img {
    width: 24px;
}

.btn-codigo img:hover {
    filter: contrast(0.5);
}

.btn-cupom button,
.conteudo-modal button {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    margin-top: 20px;
    background-color: #FF681E;
    font-size: 32px;
    letter-spacing: 1px;
    border: none;
    color: white;
    height: 55px;
    width: 100%;
}

.conteudo-modal {
    margin-bottom: 20px;
}

.btn-bloqueado {
    border: 1px solid #848484 !important;
    color: #848484 !important;
    background-color: #52575A !important;
}

.removerCupom {
    display: none !important;
}

.txt-cupons {
    margin-bottom: 5px;
}

.validade {
    font-family: "klavika medium";
    color: #FFFFFF;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
}

.btn-resgatado {
    background-color: #968A83;
    margin-top: 20px;
    justify-content: center;
    gap: 20px;
}

.btn-codigo img {
    cursor: pointer;
}

.codigo {
    cursor: default;
    letter-spacing: 1px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    font-size: 36px;
    text-transform: uppercase;
    padding: 0px;
    background-color: transparent;
    border: none;
    width: 110px;
    text-align: center;
    color: white;
}

.btn-codigo {
    margin-top: 5px;
    justify-content: center;
    gap: 20px;
}

.btn-codigo p,
.btn-resgatado p {
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    color: white;
    font-size: 36px;
    margin-bottom: 0px;
}

.icone-cupom {
    width: 17%;
}

.bloqueado {
    background-color: #52575A;
    line-height: 32px;
    padding: 15px;
    min-height: 180px;
    border: 1px solid #848484 !important;
    align-items: start;
    display: grid;
}

.nao-resgatado {
    background-color: #FF681E !important;
    line-height: 32px;
    padding: 15px;
    min-height: 180px;
    align-items: start;
    align-content: space-between;
    display: grid;
}

.resgatado {
    color: white !important;
    background-color: #407BBF !important;
}

.utilizado {
    background-color: #6F8473 !important;
}

.btn-utilizado {
    background-color: #6F8473 !important;
    cursor: default !important;
}

.cupons {
    justify-content: start;
    gap: 20px;
    overflow: auto;
    padding-bottom: 50px;
    margin-top: 25px;
}

.cupom {
    width: 308px !important;
    background-color: #52575A85;
    border: 3px solid #FFFFFF;
    padding: 10px;
}

.txt-cupom {
    color: white;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 29px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
}

.txt-pontos {
    font-family: "Knockout";
    color: #FFFFFF;
    font-size: 60px;
}

.desconto {
    font-family: "Knockout";
    color: #FFFFFF;
    font-size: 40px;
}

.conteudo-beneficios {
    position: relative;
    z-index: 999;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 50px;
    margin-top: 50px;
}

.saldos {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.conteudo-beneficios h2 {
    letter-spacing: 1.54px;
    font-family: "Knockout";
    color: #FF681E;
    font-size: 108px;
    line-height: 100px;
}

.conteudo-beneficios .txt-informativo {
    margin: 0 auto;
    width: 700px;
    color: #FFFFFF;
    letter-spacing: 1.8px;
    font-size: 19px;
    line-height: 23px;
    text-transform: uppercase;
}

.txt-desbloqueio {
    margin: 0 auto;
    width: 700px;
    color: #FFFFFF;
    text-align: left;
    letter-spacing: 1px;
    font-size: 19px;
    line-height: 20px;
    margin-bottom: 20px;
    font-family: "klavika bold";
}

.txt-desbloqueio a {
    color: #FFFFFF;
}

.link-pontos {
    font-family: "klavika medium";
    color: white !important;
}

.pontos {
    height: 95px;
    margin-top: -15px;
    justify-content: center;
}

.pontos p {
    color: #FF681E;
    font-family: "KNOCKOUT-HTF70";
    font-size: 80px;
}

.pontos span {
    margin-top: 10px;
    color: #FF681E;
    font-family: "klavika medium";
    font-size: 40px;
}

.anterior {
    background-color: transparent;
    border: none;
}

.seta {
    width: 14px;
    margin-right: 15px;
    cursor: pointer;
}

.voltar {
    padding: 0px !important;
    display: flex;
    margin: 15px 0px 30px 0px;
}

.voltar span {
    color: #fff;
    font-weight: 200;
}

.voltar p {
    margin-bottom: 0px;
    line-height: 17px;
    color: #fff;
    font-size: 14px;
    font-family: "klavika-web", sans-serif;
    font-weight: 700;
}

.logo-rodape {
    margin: 0 auto;
    padding-top: 80px;
}

.logo-mobile {
    width: 200px;
    top: 35px;
    left: 20px;
}

.menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
}

.nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
}

.nav-item a:hover {
    color: #D4D1C4;
}

.active a:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FF681E;
    margin-left: 12px;
    position: relative;
    top: -3px;
}

.active a {
    color: #D4D1C4 !important;
}

.mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px !important;
    padding-top: 20px;
}

.copyright {
    margin-top: 20px;
}

.selo {
    margin: 0 auto;
    border: 2px dashed #FF681E;
    padding: 5px 20px;
    align-items: center;
    width: 230px;
    height: 125px;
    gap: 8px;
    text-align: center;
    margin-bottom: 50px;
}

.selo-saldo {
    justify-content: space-between;
    border: 2px dashed #D4D1C4;
    padding: 5px 10px;
    align-items: center;
    width: 100%;
    height: 95px;
    gap: 20px;
}

.qtd-ind {
    font-family: "KNOCKOUT-HTF70" !important;
    color: #FF681E !important;
    font-size: 70px !important;
    margin-bottom: 0px !important;
    margin-top: 6px !important;
}

.concluido {
    margin-bottom: 0px !important;
    color: #FBFBFB !important;
    font-family: "Knockout";
    font-size: 34px !important;
    line-height: 37px;
    letter-spacing: 2px;
}

.barra-progresso {
    margin-top: 10px;
}

.estiloMobile {
    justify-content: center !important;
}

@media (min-width: 991px) {
    .vermobile {
        display: none;
    }
}

@media (max-width: 990px) {

    .versaldo {
        justify-content: start;
    }

    .btn-trava {
        width: 100%;
    }

    .btn-bloqueio {
        width: 100%;
        margin-bottom: 20px;
    }

    .selo-saldo {
        margin-top: 20px;
    }

    .conteudo-beneficios {
        margin-bottom: 30px !important;
        display: block !important;
    }

    .box-travas {
        display: block !important;
    }

    .txt-desbloqueio {
        width: 100%;
        text-align: left;
    }

    .verdesktop {
        display: none;
    }

    .cupons {
        justify-content: start;
    }

    .cupom {
        width: 300px;
    }

    .btn-cupom button {
        margin-top: 0px;
    }

    .conteudo-beneficios h2 {
        text-align: left;
        font-size: 70px;
        line-height: 50px
    }

    .conteudo-beneficios .txt-informativo {
        text-align: left;
        width: 100%;
    }

    .cupons {
        overflow: auto;
        padding-bottom: 20px;
    }

    .cupom {
        margin-bottom: 20px;
    }

    .selo {
        margin-bottom: 70px;
    }

    .logo-mobile {
        width: 150px;
    }

    .copyright {
        margin-bottom: 30px;
    }

    /*.bgbeneficio {
    background-image: url('@/assets/img/beneficios-mob.png');
  }*/

}

.txt-colaborador {
    text-align: center !important;
}

.btn-colaborador {
    margin: auto !important;
}

.versaldo {
    display: grid;
    justify-content: end;
}

.btn-saldo {
    font-family: "klavika medium";
    background-color: #FF681E;
    color: white;
    margin-top: 20px;
    padding: 10px 50px;
    text-decoration: none;
    font-size: 18px;
    border: none;
    text-align: center;
}

.btn-saldo:hover {
    background-color: #ec5d16;
}

#beneficios {
    position: relative;
}

.bloqueio {
    width: 100%;
    position: absolute;
}

.box-travas {
    justify-content: start;
    gap: 50px;
}

.qtd-indicacoes {
    gap: 5px;
    align-items: center;
    justify-content: end;
}

.icone-indicacao {
    width: 50px;
}

.qtd-indicacoes h3 {
    color: white;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    font-size: 50px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.interrogacao {
    margin-top: -45px;
    cursor: pointer;
    width: 30px;
}

.txt-indicacaoes {
    text-align: right;
    margin-bottom: 0px !important;
    color: white;
    margin-top: -10px;
    font-size: 25px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
}

.btn-trava {
    transition: background-color 0.3s ease;
    background-color: #00ABC8;
    color: white;
    font-size: 18px;
    border: none;
    text-align: center;
    display: block;
    width: 300px;
    text-decoration: none;
    padding: 12px 0px;
}

.btn-bloqueio {
    transition: background-color 0.3s ease;
    background-color: #00ABC8;
    color: white;
    font-size: 18px;
    border: none;
    text-align: center;
    display: block;
    width: 300px;
    text-decoration: none;
    padding: 12px 0px;
}

.btn-trava:hover,
.btn-bloqueio:hover {
    background-color: #00b6d5;
}

.estiloPersonalizacao .nomemodal {
    text-align: center !important;
    border-bottom: 1px solid black;
}

.posicao-beneficio {
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    color: #232527;
    font-size: 20px;
    text-align: center;
    top: 30px !important;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    left: 0px;
}

.selo-numero {
    position: relative;
}

.selo-copo {
    width: 150px;
    margin: auto;
    padding-bottom: 20px;
}

.txt-parabens h2 {
    font-family: "KNOCKOUT-HTF70";
    color: #232527;
    font-size: 35px;
    text-align: center;
}

.txt-parabens h3 {
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    color: #232527;
    font-size: 23px;
    text-align: center;
}

.usarCupom {
    margin-top: 0px !important;
    margin-bottom: 20px;
    background-color: #232527 !important;
    font-size: 25px !important;
}

.bgbeneficio {
    /*background-image: url('@/assets/img/bgbeneficios.png');
    background-size: cover;
    background-repeat: no-repeat;*/
    padding-bottom: 40px;
    background-color: #565959;
}

.notificacao.error {
    width: 500px;
    margin: 0 auto;
    display: table;
    margin-bottom: 30px;
    background: rgb(255 212 214);
}

.icone-copiar {
    cursor: pointer;
    filter: invert(1);
}

.codigo-cupom {
    padding: 0px;
    border: none;
    font-family: "klavika medium";
    color: #000000;
    font-size: 25px;
}

.usoCupom {
    font-family: "klavika medium";
    margin-top: 10px;
    text-align: center;
    letter-spacing: 0.5px;
    color: white;
    font-size: 15px;
}

.dataCupom {
    text-align: center;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    margin-bottom: 0px;
}

.dataCupom span {
    font-family: "klavika medium";
    color: #87b21b;
    font-size: 15px;
    letter-spacing: 0.5px;
}

.dataCupom p {
    padding: 5px 0px;
    margin-bottom: 0px;
}

.error p {
    color: #bf0000;
    font-size: 13px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 0px;
    line-height: 14px;
    margin-top: 10px;
    padding: 10px;
    background: rgb(255 212 214);
}

.box-cupom-resgatado {
    padding: 0px 5px;
    justify-content: center;
}

.btn-cupom img {
    width: 24px;
}

.btn-codigo img:hover {
    filter: contrast(0.5);
}

.btn-cupom button,
.conteudo-modal button {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    margin-top: 20px;
    background-color: #FF681E;
    font-size: 32px;
    letter-spacing: 1px;
    border: none;
    color: white;
    height: 55px;
    width: 100%;
}

.conteudo-modal {
    margin-bottom: 20px;
}

.btn-bloqueado {
    border: 1px solid #848484 !important;
    color: #848484 !important;
    background-color: #52575A !important;
}

.removerCupom {
    display: none !important;
}

.txt-cupons {
    margin-bottom: 5px;
}

.validade {
    font-family: "klavika medium";
    color: #FFFFFF;
    font-size: 15px;
    text-transform: uppercase;
    text-align: center;
}

.btn-resgatado {
    background-color: #968A83;
    margin-top: 20px;
    justify-content: center;
    gap: 20px;
}

.btn-codigo img {
    cursor: pointer;
}

.codigo {
    cursor: default;
    letter-spacing: 1px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    font-size: 36px;
    text-transform: uppercase;
    padding: 0px;
    background-color: transparent;
    border: none;
    width: 110px;
    text-align: center;
    color: white;
}

.btn-codigo {
    margin-top: 5px;
    justify-content: center;
    gap: 20px;
}

.btn-codigo p,
.btn-resgatado p {
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
    color: white;
    font-size: 36px;
    margin-bottom: 0px;
}

.icone-cupom {
    width: 17%;
}

.bloqueado {
    background-color: #52575A;
    line-height: 32px;
    padding: 15px;
    min-height: 180px;
    border: 1px solid #848484 !important;
    align-items: start;
    display: grid;
}

.nao-resgatado {
    background-color: #FF681E !important;
    line-height: 32px;
    padding: 15px;
    min-height: 180px;
    align-items: start;
    display: grid;
}

.resgatado {
    color: white !important;
    background-color: #407BBF !important;
}

.utilizado {
    background-color: #6F8473 !important;
}

.btn-utilizado {
    background-color: #6F8473 !important;
    cursor: default !important;
}

.cupons {
    justify-content: start;
    gap: 20px;
    overflow: auto;
    padding-bottom: 50px;
}

.cupom {
    width: 209px;
    background-color: #52575A85;
    border: 3px solid #FFFFFF;
    padding: 10px;
}

.txt-cupom {
    color: white;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 29px;
    font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
}

.txt-pontos {
    font-family: "Knockout";
    color: #FFFFFF;
    font-size: 60px;
}

.desconto {
    font-family: "Knockout";
    color: #FFFFFF;
    font-size: 40px;
}

.conteudo-beneficios {
    position: relative;
    z-index: 999;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 50px;
}

.saldos {
    text-align: center;
    justify-content: center;
    align-items: center;
}

.conteudo-beneficios h2 {
    letter-spacing: 1.54px;
    font-family: "Knockout";
    color: #FF681E;
    font-size: 108px;
    line-height: 100px;
}

.conteudo-beneficios .txt-informativo {
    margin: 0 auto;
    width: 700px;
    color: #FFFFFF;
    letter-spacing: 1.8px;
    font-size: 19px;
    line-height: 23px;
    text-transform: uppercase;
}

.txt-desbloqueio {
    margin: 0 auto;
    width: 700px;
    color: #FFFFFF;
    text-align: left;
    letter-spacing: 1px;
    font-size: 19px;
    line-height: 20px;
    margin-bottom: 20px;
    font-family: "klavika bold";
}

.txt-desbloqueio a {
    color: #FFFFFF;
}

.link-pontos {
    font-family: "klavika medium";
    color: white !important;
}

.pontos {
    height: 95px;
    margin-top: -15px;
    justify-content: center;
}

.pontos p {
    color: #FF681E;
    font-family: "KNOCKOUT-HTF70";
    font-size: 80px;
}

.pontos span {
    margin-top: 10px;
    color: #FF681E;
    font-family: "klavika medium";
    font-size: 40px;
}

.anterior {
    background-color: transparent;
    border: none;
}

.seta {
    width: 14px;
    margin-right: 15px;
    cursor: pointer;
}

.voltar {
    padding: 0px !important;
    display: flex;
    margin: 15px 0px 30px 0px;
}

.voltar span {
    color: #fff;
    font-weight: 200;
}

.voltar p {
    margin-bottom: 0px;
    line-height: 17px;
    color: #fff;
    font-size: 14px;
    font-family: "klavika-web", sans-serif;
    font-weight: 700;
}

.logo-rodape {
    margin: 0 auto;
    padding-top: 80px;
}

.logo-mobile {
    width: 200px;
    top: 35px;
    left: 20px;
}

.menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
}

.nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
}

.nav-item a:hover {
    color: #D4D1C4;
}

.active a:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FF681E;
    margin-left: 12px;
    position: relative;
    top: -3px;
}

.active a {
    color: #D4D1C4 !important;
}

.mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px !important;
    padding-top: 20px;
}

.copyright {
    margin-top: 20px;
}

.selo {
    margin: 0 auto;
    border: 2px dashed #FF681E;
    padding: 5px 20px;
    align-items: center;
    width: 230px;
    height: 125px;
    gap: 8px;
    text-align: center;
    margin-bottom: 50px;
}

.selo-saldo {
    justify-content: space-between;
    border: 2px dashed #D4D1C4;
    padding: 5px 10px;
    align-items: center;
    width: 100%;
    height: 95px;
    gap: 20px;
}

.qtd-ind {
    font-family: "KNOCKOUT-HTF70" !important;
    color: #FF681E !important;
    font-size: 70px !important;
    margin-bottom: 0px !important;
    margin-top: 6px !important;
}

.concluido {
    margin-bottom: 0px !important;
    color: #FBFBFB !important;
    font-family: "Knockout";
    font-size: 34px !important;
    line-height: 37px;
    letter-spacing: 2px;
}

.barra-progresso {
    margin-top: 10px;
}

.estiloMobile {
    justify-content: center !important;
}

@media (min-width: 991px) {
    .vermobile {
        display: none;
    }
}

@media (max-width: 990px) {

    .versaldo {
        justify-content: start;
    }

    .btn-trava {
        width: 100%;
    }

    .btn-bloqueio {
        width: 100%;
        margin-bottom: 20px;
    }

    .selo-saldo {
        margin-top: 20px;
    }

    .conteudo-beneficios {
        margin-bottom: 30px !important;
        display: block !important;
    }

    .box-travas {
        display: block !important;
    }

    .txt-desbloqueio {
        width: 100%;
        text-align: left;
    }

    .verdesktop {
        display: none;
    }

    .cupons {
        justify-content: start;
    }

    .cupom {
        width: 209px;
    }

    .btn-cupom button {
        margin-top: 0px;
    }

    .conteudo-beneficios h2 {
        text-align: left;
        font-size: 70px;
        line-height: 50px
    }

    .conteudo-beneficios .txt-informativo {
        text-align: left;
        width: 100%;
    }

    .cupons {
        overflow: auto;
        padding-bottom: 20px;
    }

    .cupom {
        margin-bottom: 20px;
    }

    .selo {
        margin-bottom: 70px;
    }

    .logo-mobile {
        width: 150px;
    }

    .copyright {
        margin-bottom: 30px;
    }

    /*.bgbeneficio {
    background-image: url('@/assets/img/beneficios-mob.png');
  }*/

}

.home {
    height: 100vh;
}

.video {
    z-index: -9;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-final {
    bottom: 0px;
    position: absolute;
    right: 70px;
}

.img-final img {
    width: 270px;
}

.btn-personalizar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 24px;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    padding: 20px 30px;
    letter-spacing: 0.36px;
    font-family: "klavika-web", sans-serif;
}

.btn-personalizar:hover {
    background-color: #00b6d5;
}

.informacoes {
    bottom: 70px;
    position: absolute !important;
}

.informacoes h1 {
    font-family: "Knockout";
    text-transform: uppercase;
    letter-spacing: 6px;
    color: #FFFFFF;
    text-shadow: 0px 0px 63px #00000073;
    font-size: 120px;
}

.informacoes p {
    font-size: 62px;
    color: #FFFFFF;
    font-family: "klavika-web", sans-serif;
    margin-bottom: -20px;
    ;
}

.btn-mobile {
    padding: 5px !important;
    top: 20px;
    right: 15px;
    position: absolute;
    border: none;
    background-color: transparent;
}

.btn-mobile:hover,
.btn-mobile:focus,
.btn-mobile:active,
.side-mob .btn:active {
    border: none !important;
    background-color: transparent !important;
}

.logo-mobile {
    width: 150px;
    top: 35px;
    left: 20px;
    position: absolute;
}

.menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
}

.nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
}

.nav-item a:hover {
    color: #D4D1C4;
}

.active a:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #FF681E;
    margin-left: 12px;
    position: relative;
    top: -3px;
}

.active a {
    color: #D4D1C4 !important;
}

.menu-desk {
    position: absolute;
    width: 100%;
}

@media (min-width: 990px) {

    video.desk {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .mobile,
    .desktop {
        display: none !important;
    }

    .video-container {
        position: relative;
        height: 100vh;
    }
}

@media (max-width: 989px) {

    .video-container {
        position: relative;
        width: 100%;
        height: 100vh;
    }

    .menu-banner {
        position: absolute;
        width: 100%;
        padding: 0px 15px;
    }

    .video::-webkit-media-controls {
        display: none !important;
    }

    .video::-webkit-media-controls-enclosure {
        display: none !important;
    }

    .video::-webkit-media-controls-panel {
        display: none !important;
    }

    .video.videodesk {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .desk {
        display: none;
    }

    .informacoes {
        left: 20px;
        bottom: 100px;
    }

    .informacoes h1 {
        font-size: 60px;
        letter-spacing: 2px;
    }

    .informacoes p {
        left: 20px;
        font-size: 33px;
        margin-bottom: -5px;
    }

    .btn-personalizar {
        font-size: 15px;
        height: 45px;
        padding: 10px 20px;
        margin-top: 0px;
    }
}
</style>